import React from 'react';

const TemplateItemPreview = ({ htmlContent }) => {
  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    boxShadow: '0px 4px 4px 0px #00000040',
    borderRadius: '5px',
  };

  const customStyles = `
    <style>
      ::-webkit-scrollbar { 
        width: 0px; 
        background: transparent;
      }
      
      body {
        margin: 0;
        padding: 0;
        scrollbar-width: none; 
      }

      .preview-container {
        display: flex;
        justify-content: center;
        align-items: flex-start; 
        min-height: 100vh;
        padding: 20px;
        box-sizing: border-box;
        overflow: auto; 
        -webkit-overflow-scrolling: touch;
        -webkit-user-select: none; 
        -ms-user-select: none; 
        user-select: none;
      }

      /* The scaled preview area */
      .preview-scale {
        transform: scale(0.8); 
        transform-origin: top center;
        text-align: center;
      }

      @media (max-width: 600px) {
        .preview-scale {
          transform: scale(0.6);
        }
      }
      @media (max-width: 400px) {
        .preview-scale {
          transform: scale(0.5);
        }
      }
    </style>
  `;

  const reshapedHtml = `
    <!DOCTYPE html>
    <html>
      <head>
        ${customStyles}
      </head>
      <body>
        <div class="preview-container">
          <div class="preview-scale">
            ${htmlContent}
          </div>
        </div>
      </body>
    </html>
  `;

  return (
    <div
      className="template-item-preview"
      style={{ all: 'unset', width: '100%', height: '100%' }}
    >
      <iframe
        id="test"
        style={iframeStyle}
        srcDoc={reshapedHtml}
        title="Template Preview"
        seamless="true"
        scrolling="no"
      />
    </div>
  );
};

export default TemplateItemPreview;