import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import linkStore from "./../../assets/Images/linkStore.svg";
import brokenChain from "./../../assets/Images/brokenChain.svg";
import trueTickMark from "./../../assets/Images/trueTickMark.svg";
import questionCircle from "./../../assets/Images/questionCircle.svg";

import {
  setLoginData,
  setVerificationDetails,
} from "store/actions/loginAction";
import "./linkStore.css";
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import sdk from "sdk/Accounts";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { createShopifyProfile, updateCustomerAccount } from "graphql/mutations";
import { useHistory } from "react-router-dom";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

const LinkStore = ({ organizationList }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [createOrgForm] = Form.useForm();

  const { userData, verificationDetails } = useSelector(
    (state) => state.loginReducer
  );

  const [notLinkedOrganizations, setNotLinkedOrganizations] = useState(null);
  const [linkedOrganizations, setLinkedOrganizations] = useState(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [showCreateOrganizationModal, setShowCreateOrganizationModal] =
    useState(false);
  const [createCompanyLoader, setCreateCompanyLoader] = useState(false);
  const [linkStoreLoader, setLinkStoreLoader] = useState(false);
  useEffect(() => {
    createOrgForm.setFieldsValue({
      company_name: verificationDetails?.shopify_profile?.store_name,
      company_url: verificationDetails?.shopify_profile?.shopifyStoreDomain,
    });
    const linked = [];
    const notLinked = [];
    organizationList?.forEach((org) => {
      if (org?.isshopifyProfileAttached) {
        linked.push(org);
      } else {
        notLinked.push(org);
      }
    });
    setSelectedOrganizationId(notLinked?.[0]?.id);
    setLinkedOrganizations(linked);
    setNotLinkedOrganizations(notLinked);
  }, []);

  const notifyHubSpot = async () => {
    const apiName = "blustreamREST";
    let path =
      "/shopify-intergration/shopify_webhooks_register_and_notify_hubspot";

    let body = {
      user_email: userData?.email,
      user_fname: userData?.given_name,
      user_lname: userData?.family_name,
      shopifyProfileId: verificationDetails?.shopify_profile?.shopifyStoreId,
      shopifyStoreDomain:
        verificationDetails?.shopify_profile?.shopifyStoreDomain,
      shopifyStoreName: verificationDetails?.shopify_profile?.store_name,
      shopifyPhone: verificationDetails?.shopify_profile?.shopifyPhoneNumber,
    };
    await API.post(apiName, path, { body: body });
  };

  const addNewShopifyProfile = async (accountId, isNew = false) => {
    const shopifyProfile = { ...verificationDetails?.shopify_profile };
    delete shopifyProfile.shopifyPhoneNumber;
    const response = await API.graphql(
      graphqlOperation(createShopifyProfile, {
        input: {
          ...shopifyProfile,
          account: accountId,
          enabled: true,
          id: verificationDetails?.shopify_profile?.shopifyStoreId,
          isProfileLock: isNew,
          showSteper: isNew,
        },
      })
    );
    return response;
  };

  const updateUserGroups = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      const { accessToken } = session;
      if (CurrentUserSessionHelper.accessTokenHasCognitoGroups(accessToken)) {
        const response = Auth.currentUserInfo();
        response.then((data) => {
          dispatch(setLoginData(session, data?.attributes));
        });
      }
    });
  };

  const createOrganization = async (formValues) => {
    setCreateCompanyLoader(true);
    let result = await sdk.createAccount(
      formValues?.company_name,
      formValues?.company_url,
      [],
      true
    );
    if (result.error) {
      message.error(result.error);
      setCreateCompanyLoader(false);
    } else {
      try {
        await API.graphql(
          graphqlOperation(updateCustomerAccount, {
            input: { id: result?.msg, source: "shopify", manualBilling: false },
          })
        );
        await addNewShopifyProfile(result?.msg, true);
        await notifyHubSpot();
        await updateUserGroups();
        message.success(`Organization created successfully!`);
        setCreateCompanyLoader(false);
        setShowCreateOrganizationModal(false);
        dispatch(
          setVerificationDetails({
            is_shopify_store_linked: true,
            is_user_authorized: true,
            shopify_profile: {
              ...verificationDetails?.shopify_profile,
              account: result?.msg,
              id: verificationDetails?.shopify_profile?.shopifyStoreId,
            },
          })
        );
      } catch (error) {
        console.log(error);
        message.error(`Something went wrong!`);
        setCreateCompanyLoader(false);
        setShowCreateOrganizationModal(false);
      }
    }
  };

  const attachShopifyProfile = async () => {
    try {
      setLinkStoreLoader(true);
      const selectedOrganization = notLinkedOrganizations?.find(
        (org) => org?.id === selectedOrganizationId
      );
      await addNewShopifyProfile(
        selectedOrganizationId,
        selectedOrganization?.source === "shopify"
      );
      if (selectedOrganization?.source === "shopify") {
        await API.graphql(
          graphqlOperation(updateCustomerAccount, {
            input: { id: selectedOrganizationId, manualBilling: false },
          })
        );
      }
      await notifyHubSpot();
      setLinkStoreLoader(false);
      dispatch(
        setVerificationDetails({
          is_shopify_store_linked: true,
          is_user_authorized: true,
          shopify_profile: {
            ...verificationDetails?.shopify_profile,
            account: selectedOrganizationId,
            isProfileLock: selectedOrganization?.source === "shopify",
            showSteper: selectedOrganization?.source === "shopify",
            id: verificationDetails?.shopify_profile?.shopifyStoreId,
          },
        })
      );
    } catch (error) {
      console.log(error);
      setLinkStoreLoader(false);
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={20} className="link-store-main-container">
          {organizationList && organizationList?.length > 0 && (
            <Button
              className="standard-button tertiary-button skip-button"
              onClick={() => {
                localStorage.removeItem("storeDetails");
                history.push("/");
              }}
            >
              Skip
            </Button>
          )}
          <div className="connect-organization-container">
            <Row justify="center" style={{ width: "100%" }}>
              <Col span={10}>
                <Row className="connect-organization-content-row">
                  <Col span={24} className="connect-organization-content-col">
                    <img src={linkStore} alt="linkStore" />
                    <p className="welcome-text">Welcome to Blustream</p>
                    <p className="help-text">
                      Select Blustream organization to connect your Shopify
                      store{" "}
                    </p>
                    <p className="store-name">
                      {verificationDetails?.shopify_profile?.store_name}
                    </p>
                    {organizationList && organizationList?.length > 0 ? (
                      <div style={{ width: "100%" }}>
                        <Divider className="organization-boundary-above" />
                        <div className="organizations-list">
                          {notLinkedOrganizations?.length > 0 && (
                            <Radio.Group
                              onChange={(e) => {
                                setSelectedOrganizationId(e.target.value);
                              }}
                              value={selectedOrganizationId}
                            >
                              {notLinkedOrganizations?.map((organization) => {
                                return (
                                  <Radio value={organization?.id}>
                                    {organization?.company}
                                  </Radio>
                                );
                              })}
                            </Radio.Group>
                          )}

                          {linkedOrganizations?.length > 0 &&
                            linkedOrganizations?.map((organization) => {
                              return (
                                <div className="linked-organization-container">
                                  <img src={trueTickMark} alt="trueTickMark" />
                                  <span>{organization?.company}</span>
                                </div>
                              );
                            })}
                        </div>
                        <Divider className="organization-boundary-below" />
                        <div className="disable-store-note-container">
                          <span className="organization-note-text">
                            *Note: All disable orgaizations are linked to
                            Shopify stores
                          </span>
                          <Tooltip
                            className="disconnect-shopify-steps-tooltip-container"
                            color="#F5F5F5"
                            placement="right"
                            title={
                              <div className="disconnect-shopify-steps-tooltip">
                                <div className="tooltip-title">
                                  Want to connect with different Shopify store?
                                </div>
                                <div className="tooltip-steps-container">
                                  <div>1.Login to Shopify store</div>
                                  <div>2.Launch the Blustream app</div>
                                  <div>3.Go to integration tab</div>
                                  <div>
                                    4.Click ‘Disconnect App’ from the bottom
                                  </div>
                                  <div>
                                    5.Login to the new Shopify store you want to
                                    connect
                                  </div>
                                  <div>6.Install the Blustream app</div>
                                </div>
                              </div>
                            }
                          >
                            <img src={questionCircle} alt="questionCircle" />
                          </Tooltip>
                        </div>
                      </div>
                    ) : (
                      <div className="empty-organization-placeholder">
                        <img
                          className="broken-chain-image"
                          src={brokenChain}
                          alt="brokenChain"
                        />
                        <p className="placeholder-text">
                          You are not linked to any Blustream organization yet!
                        </p>
                      </div>
                    )}

                    <div className="link-store-buttons-container">
                      <Button
                        className="standard-button secondary-orange-button"
                        onClick={() => {
                          setShowCreateOrganizationModal(true);
                        }}
                      >
                        Create Organization
                      </Button>
                      {notLinkedOrganizations?.length > 0 ? (
                        <Button
                          className="standard-button primary-orange-button"
                          onClick={attachShopifyProfile}
                          loading={linkStoreLoader}
                        >
                          Continue
                        </Button>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal
        visible={showCreateOrganizationModal}
        className="create-org-modal"
        footer={null}
        width={431}
        centered
        closeIcon={<CloseOutlined className="close-button" />}
        onCancel={() => {
          setShowCreateOrganizationModal(false);
          createOrgForm.setFieldsValue({
            company_name: verificationDetails?.shopify_profile?.store_name,
            company_url:
              verificationDetails?.shopify_profile?.shopifyStoreDomain,
          });
        }}
      >
        <div className="create-org-modal-body">
          <Typography.Title className="modal-title">
            Create a new Organization
          </Typography.Title>
          <Typography.Title className="instruction-text">
            Please fill in the following information for your company so we can
            provision your trial account
          </Typography.Title>
          <Form
            form={createOrgForm}
            requiredMark={false}
            layout="vertical"
            style={{ width: "100%" }}
            className="create-org-for-shopify-form"
            onFinish={createOrganization}
          >
            <Form.Item
              className="company-name"
              name="company_name"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of your company",
                },
                {
                  pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)/,
                  message: "Please enter valid company name",
                },
              ]}
            >
              <Input
                className="standard-input"
                placeholder="Company Name"
              ></Input>
            </Form.Item>
            <Form.Item
              className="company-url"
              name="company_url"
              label="Company URL"
              rules={[
                {
                  required: true,
                  message: "Please enter the URL of your company",
                },
                {
                  pattern:
                    /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                  message: "Please enter valid URL",
                },
              ]}
            >
              <Input
                className="standard-input"
                placeholder="Company URL"
              ></Input>
            </Form.Item>

            <Form.Item className="submit-button">
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={createCompanyLoader}
                className="standard-button primary-orange-button"
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default LinkStore;
