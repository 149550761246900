import { API } from "aws-amplify";
import axios from "axios";
import { store } from "../store";
import Auth from "@aws-amplify/auth";

const interceptor = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

interceptor.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    console.log("Api error: ", { err });
    throw err;
  }
);

const requestInit = async (body = {}) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
  const jwtToken = currentAuthenticatedUser.signInUserSession?.accessToken?.jwtToken

  let headersObj = {}
  if(jwtToken) {
    headersObj['Authorization'] = 'Bearer ' + jwtToken
  }

  return {
    headers: headersObj,
    body: body
  }
}

export const getAttribute = async (customerId) => {
  let init = await requestInit({
    dataClassAttributeDtoExample: {
      customerDto: {
        customerId: customerId,
      },
      containerDataClassDto: {
        containerDataClassId: 7,
      }
    }
  });

  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/data_class_attribute/find",
    init
  );
};

export const addAttribute = async ({
  AttributeTitle,
  AttributeType,
  customerId,
}) => {
  let init = await requestInit({
    customerId: customerId,
    name: AttributeTitle,
    containerDataClassId: 9,
    typeDataClassId: AttributeType
  });
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/data_class_attribute/add",
    init
  );
};

export const getProduct = async (id) => {
  let response = await getCustomerId(id);
  let customerId = response.response?.data?.[0]?.customerId;
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/product/find?productDtoExample.CustomerDto.CustomerId=${customerId}&dataClassAttributeDtoExample.isActive=true`,
    await requestInit()
  );
};

export const addOrUpdateContactAttributeValue = async (dataClassAttributeId, contactId, newAttributeValue) => {
  let valueToSend = `&value=${newAttributeValue}`
  if (newAttributeValue === null || newAttributeValue === undefined || newAttributeValue === '') {
    valueToSend = ""
  }

  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/addOrUpdateContactAttributeValue?contactId=${contactId}&dataClassAttributeId=${dataClassAttributeId}${valueToSend}`,
    await requestInit()
  );
};

export const getAttributeValues = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/findContactAttributeValues?contactId=${id}`,
    await requestInit()
  );
};

export const getAllAttributeValues = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_attribute/find?dataClassAttributeDtoExample.containerDataClass.dataClassId=7&dataClassAttributeDtoExample.customerDto.customerId=${id}`,
    await requestInit()
  );
};

export const createContact = async ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
}) => {
  const { loginReducer } = store.getState();
  let id = loginReducer?.groups?.[0];

  let init = await requestInit({
    firstName,
    lastName,
    primaryEmailAddress: emailAddress,
    primaryPhoneNumber: phoneNumber,
    customerExternalUuid: id
  });

  return API.post("blustreamREST", "/gandalfproxy/api/contact/add", init);
};
export const updateContact = async ({
  acctid,
  firstName,
  lastName,
  primaryEmailAddress,
  primaryPhoneNumber,
}) => {
  let init = await requestInit({
    contactId: acctid,
    firstName: firstName,
    lastName: lastName,
    primaryEmailAddress: primaryEmailAddress,
    primaryPhoneNumber: primaryPhoneNumber,
  });

  return API.post("blustreamREST", "/gandalfproxy/api/contact/update", init);
};

export const deleteContact = async (id) => {
  let init = await requestInit({
    id: id
  });

  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/contact/delete-by-id",
    init
  );
};

export const getContactbyID = async (contactId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact/find-by-id?id=${contactId}`,
    await requestInit()
  );
};

export const getAllContacts = async (accountID) => {
  let init = await requestInit({
    contactExample: {
      customerExternalUuid: `${accountID}`,
      isActive: true,
    }
  });

  return API.post("blustreamREST", "/gandalfproxy/api/contact/find", init);
};


export const AddProduct = async (contactId, productId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact_product/add?contactId=${contactId}&productId=${productId}`,
    await requestInit()
  );
};
export const getContactProductRelations = async (customerId, contactId = null, productId = null, isActive = true) => {
    let endpoint = `find?contactProductDtoExample.contactDto.customerDto.customerId=${customerId}&contactProductDtoExample.isActive=${isActive}`;
    if (contactId !== null && contactId !== undefined) {
      endpoint += `&contactProductDtoExample.contactDto.contactId=${contactId}`;
    }

    if (productId !== null && productId !== undefined) {
      endpoint += `&contactProductDtoExample.productDto.productId=${productId}`;
    }

    return await API.get(
        "blustreamREST",
        `/gandalfproxy/api/contact_product/${endpoint}`,
        requestInit()
    );
};

export const getContactProductAttributeName = async (customerId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_attribute/find?dataClassAttributeDtoExample.containerDataClass.dataClassId=9&dataClassAttributeDtoExample.customerDto.customerId=${customerId}`,
    await requestInit()
  );
};

export const getDataClassInstanceAttributeId = async (id,productId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute/find?dataClassInstanceAttributeDtoExample.dataClassInstanceDto.dataClassInstanceId=${id}&dataClassInstanceAttributeDtoExample.dataClassAttributeDto.productDto.productId=${productId}`,
    await requestInit()
  );
};

export const AddDataClassInstanceAttributeId = async (id, value) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/add?dataClassInstanceAttributeId=${id}&value=${value}`,
    await requestInit()
  );
};
export const getDataClassInstanceAttributeValue = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/findValueByAttributeInstance?dataClassInstanceAttributeId=${id}`,
    await requestInit()
  );
};

export const getTuchPointData = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/${id}/journeys?$select=Id,Name,numberOfTouchpoints`,
    await requestInit()
  );
};
export const getTuchPointIdData = async (id, journeyId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/${id}/journey/${journeyId}`,
    await requestInit()
  );
};

export const editTuchPointData = async (id, journeyId, touchpointId, data) => {
  let init = await requestInit(data);

  return API.put(
    "blustreamREST",
    `/gandalfproxy/api/customer/${id}/journey/${journeyId}/touchpoint/${touchpointId}`,
    init
  );
};

export const tresableLinkData = async (id, target_url, touchpointId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/target_url_activity_link/getLinkTracker?customerId=${id}&targetUrl=${target_url}&journeyActivity=${touchpointId}`,
    await requestInit()
  );
};

export const getCustomerId = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/find?customerDtoExample.externalUuid=${id}`,
    await requestInit()
  );
};

// export const testJourneyTouchpoint = async (
//   phoneNumber,
//   journeyId,
//   touchpointId
// ) => {
//   const { loginReducer } = store.getState();
//   let externalUuid = loginReducer?.groups?.[0];
//   let init = {
//     body: +phoneNumber,
//   };
//   return API.post(
//     "blustreamREST",
//     `/gandalfproxy/api/customer/${externalUuid}/journey/${journeyId}/touchpoint/${touchpointId}/test`,
//     init
//   );
// };

//updated function as per the shortcut-53346
export const testJourneyTouchpoint = async (contactId, journeyId, touchpointId) => {
  return API.post(
    "blustreamREST",
    `/gandalfproxy/api/contact/${contactId}/journey/${journeyId}/touchpoint/${touchpointId}`,
  );
};

export const contactSearch = async (customerArn, queryString, pageIndex, pageSize) => {
  pageIndex = pageIndex == undefined ? 0 : pageIndex - 1;
  pageSize = pageSize == undefined ? 10 : pageSize;
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customers/${customerArn}/contacts?searchQuery=${queryString}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    await requestInit()
  );
};

export const deleteContactProductRelation = async (contactProductId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact_product/delete-by-id?id=${contactProductId}`,
    await requestInit()
  );
};

export const getWhatsappConfiguration = async (customerId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/whatsapp_configuration/find?whatsappConfigurationDtoExample.customerOriginationPhoneNumberDto.customerDto.customerId=${customerId}`,
    await requestInit()
  );
};

export const addWhatsappConfiguration = async (payload) => {
  let init = await requestInit(payload);
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/whatsapp/verify-and-save",
    init
  );
};

export const getCustomerEmailTemplates = async (customerUuid) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer_email_template/customerUuid/${customerUuid}`,
    await requestInit()
  );
};

export const addCustomerEmailTemplate= async (payload) => {
  let init = await requestInit(payload);
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/customer_email_template/add",
    init
  );
};

export const addCustomerAsset = async (customerUuid, file) => {
  const formData = new FormData();
  formData.append('file', file);

  let init = await requestInit(formData);
  return API.post(
    "blustreamREST",
   `/gandalfproxy/api/customer/${customerUuid}/customer_asset`, init
  );
};

export const getCustomerAssets = async (customerUuid) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer_asset/customerUuid/${customerUuid}`,
    await requestInit()
  );
};

export const sendTestEmail = async (payload) => {
  let init = await requestInit(payload);
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/customer_email_template/send-test-email",
    init
  );
};

export const deleteCustomerAsset = async (id) => {
  let init = await requestInit({id: id});
  return API.del(
    "blustreamREST",
    `/gandalfproxy/api/customer_asset/delete-by-id`,
   init
  );
};

export const saveBrandingElements = async (customerUuid, payload) => {
  let init = await requestInit(payload);
  return API.put(
    "blustreamREST",
    `/gandalfproxy/api/customer/${customerUuid}/branding-elements`,
    init
  );
};

export const getBrandingElementsBySubdomain = async (subdomain) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/getbrandingelements?subdomain=${subdomain}`,
    await requestInit()
  );
};

