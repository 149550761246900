import React, { useState, useEffect } from "react";
import { Divider, Row } from "antd";
import TextField from "../Common/TextField";
import { useSelector, useDispatch } from "react-redux";
import { setPageSettings } from "store/actions/WebchatBrandSettingsAction";
import WebchatBrandingTabMenuIcon from "./WebchatBrandingTabMenuIcon.js";

import { useActiveCompanyAlias, useActiveCompanyName} from "hooks/CompanyHooks";


export default function WebchatBrandingTabMenuPWA({ onDataChange = () => {} }) {
    const dispatch = useDispatch();
    const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);

    const activeCompanyAliasPromise = useActiveCompanyAlias();
    const activeCompanyName = useActiveCompanyName();
    const [companyAlias, setCompanyAlias] = useState("");

    useEffect(() => {
        activeCompanyAliasPromise.then((alias) => {
            setCompanyAlias(alias);
        });
        
    }, [activeCompanyAliasPromise, companyAlias]);

    return (
        <>
            <Row gutter={[10, 10]} style={{ marginLeft: 10 }}>

                <TextField
                    label="Company Name"
                    initialValue={activeCompanyName || "Company Name"}
                    disabled={true}
                    onChange={(value) => {
                        dispatch(setPageSettings({ ...page, brandLongName: value }));
                        onDataChange();
                    }
                    }
                    placeholder="Company Name"
                />

                <TextField
                    label="Short Name"
                    initialValue={companyAlias}
                    disabled={true}
                    placeholder={companyAlias}
                />
                <div>
                <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>Home Screen Icon</h3>
                <WebchatBrandingTabMenuIcon icon={"homeScreenIcon"} onDataChange={onDataChange}/>
                </div>
                <div style={{ marginTop: 20 }}>
                <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>Notification Icon</h3>
                <WebchatBrandingTabMenuIcon icon={"notificationIcon"} onDataChange={onDataChange} />
                </div>
            </Row>
        </>
    );
}
