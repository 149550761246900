import * as actions from "store/actions";

const initialState = {
    page: {
        title: "Chat with us",
        favicon: "link-to-asset",
    },
    theme: {
        mainElementColor: "#000000",
        buttonBackgroundColor: "#ffffff",
        buttonTextColor: "#000000",
        headerImage: "link-to-asset",
    },
    chat: {
        brandMessageBackgroundColor: "#000000",
        brandMessageTextColor: "#ffffff",
        brandMessageIcon: "link-to-asset",
        contactMessageBackgroundColor: "#ffffff",
        contactMessageTextColor: "#000000",
    },
    pwa: {
        appName: "Brand Long Name",
        shortName: "Brand Short Name",
        homeScreenIcon: "",
        notificationIcon: "",
    },
    links: {
        faq: "link-to-faq",
        customerSupport: "link-to-customer-support",
        shop: "link-to-shop",
    },
    emailTemplate: {
        customerUuid: "customer-uuid",
        templateId: "selected-template-id",
        name: "selected-template-name",
        subject: "selected-template-subject",
        body: "body-content",
    }, 
    emailTemplates: [],
};

const WebchatBrandSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case actions.SET_THEME:
            return {
                ...state,
                theme: action.payload
            };
        case actions.SET_CHAT:
            return {
                ...state,
                chat: action.payload
            };
        case actions.SET_PWA:
            return {
                ...state,
                pwa: action.payload
            };
        case actions.SET_LINKS:
            return {
                ...state,
                links: action.payload
            };
        case actions.SET_EMAIL_TEMPLATE:
            return {
                ...state,
                emailTemplate: action.payload
            };
        case actions.FETCH_EMAIL_TEMPLATES_SUCCESS:
            const sortedTemplates = action.payload.response.data[0].slice().sort((a, b) => a.id - b.id);
            return {
                ...state,
                emailTemplates: sortedTemplates,
            }
        default:
            return state;
    }
};

export default WebchatBrandSettingsReducer;