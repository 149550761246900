import React, { useState } from "react";
import { Row, Col, Input } from "antd";

export default function TextField({ label = "Label Not Set", initialValue = "", onChange, disabled = false, placeholder = "Enter text" }) {
    const [text, setText] = useState(initialValue);

    const handleChange = (e) => {
        setText(e.target.value);
        onChange && onChange(e.target.value);
    };

    return (
        <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col span={24}>
                <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>{label}</h3>
            </Col>
            <Col span={24}>
                <Input
                    value={text}
                    onChange={handleChange}
                    disabled={disabled} 
                    placeholder={placeholder}
                    style={{ textAlign: "left" }}
                />
            </Col>
        </Row>
    );
}
