import React, { useState, useEffect } from "react";
import { Row } from "antd";
import TextField from "../Common/TextField";
import { useSelector, useDispatch } from "react-redux";
import { setPageSettings } from "store/actions/WebchatBrandSettingsAction";

import { useActiveCompanyAlias, } from "hooks/CompanyHooks";


export default function WebchatBrandingTabMenuLinks({ onDataChange = () => {} }) {
    const dispatch = useDispatch();
    const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);

    const activeCompanyAliasPromise = useActiveCompanyAlias();
    const [companyAlias, setCompanyAlias] = useState("");

    useEffect(() => {
        activeCompanyAliasPromise.then((alias) => {
            setCompanyAlias(alias);
        });
    }, [activeCompanyAliasPromise]);

    return (
        <>
            <Row gutter={[10, 10]} style={{ marginLeft: 10 }}>

                <TextField
                    label="FAQ"
                    disabled={false}
                    onChange={(value) => {
                        dispatch(setPageSettings({ ...page, FAQLink: value }));
                        onDataChange();
                    }}
                    placeholder={page.FAQLink || "FAQ"}
                />

                <TextField
                    label="Customer Support"
                    disabled={false}
                    onChange={(value) => {
                        dispatch(setPageSettings({ ...page, SupportLink: value }));
                        onDataChange();
                    }}
                    placeholder={page.SupportLink || "Customer Support"}
                />

                <TextField
                    label="Shop"
                    disabled={false}
                    onChange={(value) => {
                        dispatch(setPageSettings({ ...page, ShopLink: value }));
                        onDataChange();
                    }}
                    placeholder={page.ShopLink || "Shop"}
                />
            </Row>
        </>
    );
}
