
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    EditOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Row,
    Popover,
} from "antd";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { invertColor } from "../../utility/LightenDarkenColor.js";

export default function ColorPicker({ label = "Label Not Set", initialValue, onChange }) {
    const [color, setColor] = useState(initialValue || "#000000");

    const { roAdmin } = useSelector((state) => state.loginReducer);

    const handleChange = (newColor) => {
        setColor(newColor);
        if (onChange) {
            onChange(newColor); 
        }
        
    }

    const pickerPopperover = () => {
        return (
            <div style={{ padding: "20px" }}>
                <HexColorPicker
                    color={color}
                    onChange={(onChangeEvent) => handleChange(onChangeEvent)}
                    style={{ border: "1px solid black", borderRadius: "8px" }}
                />
                <HexColorInput
                    className="colorpicker-input"
                    color={color}
                    style={{
                        border: "1px solid black",
                        borderRadius: "3px",
                        marginTop: "5px",
                        width: "100%",
                        textAlign: "center",
                    }}
                    onChange={(onChangeEvent) => handleChange(onChangeEvent)}
                    placeholder="Type a color"
                />
            </div>
        );
    };

    return (
        <Row gutter={[8, 8]}>
            <Col span={24} style={{ marginTop: "20px" }}>
                <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>
                    {label}
                </h3>
            </Col>
            <Col span={12} xs={24} sm={12} md={12} lg={24} xl={18} xxl={12}>
                <div className="white-box">
                    <h4 style={{ textAlign: "center", paddingTop: 5 }}>
                        {label}
                    </h4>
                    <Row>
                        <Col
                            span={15}
                            style={{
                                background: color,
                            }}
                        >
                            <HexColorInput
                                className="colorpicker-input"
                                color={
                                    color
                                }
                                style={{
                                    background: color,
                                    paddingLeft: "10px",
                                    color: `${invertColor(color)}`,
                                }}
                                onChange={(onChangeEvent) => onChange(onChangeEvent)}
                                disabled={roAdmin}
                                placeholder="Type a color"
                            />
                        </Col>
                        <Col span={9}>
                            <Popover
                                trigger={roAdmin ? "" : "hover"}
                                content={() => pickerPopperover()}
                            >
                                <Button
                                    style={{
                                        background: "#124173",
                                        border: "none",
                                        color: "white",
                                        width: "100%",
                                        borderRadius: 0,
                                    }}
                                >
                                    <EditOutlined />
                                </Button>
                            </Popover>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}