import React, { useState } from "react";
import { CloudUploadOutlined, DeleteFilled } from "@ant-design/icons";
import { Button, Col, Row, Upload } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useActiveCompanyUuid } from "hooks/CompanyHooks";
import { setPageSettings, updateBrandingElements } from "store/actions/WebchatBrandSettingsAction";
import YourLogo from "assets/Images/YourLogo.png";

import {
    addCustomerAsset,
  } from "apiService/RESTApi";

export default function WebchatBrandingTabMenuIcon({ icon, onDataChange = () => {} }) {

  const { roAdmin } = useSelector((state) => state.loginReducer);
  const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);
  
  const dispatch = useDispatch();
  const activeCompanyUuid = useActiveCompanyUuid();
  const [uploadedImage, setUploadedImage] = useState(YourLogo);

  const uploadFile = async (options) => {
    const asset = await addCustomerAsset(activeCompanyUuid, options.file);
    options.file = '';

    if (asset == undefined || asset.url == undefined) {
        return;
    }

    dispatch(updateBrandingElements(activeCompanyUuid, { [icon]: asset.url }));
    dispatch(setPageSettings({ ...page, [icon]: asset.url }));
    setUploadedImage(asset.url);
    onDataChange();
  };

  const deleteLogo = () => {
    setUploadedImage(YourLogo);
    onDataChange();
  };

  return (
    <>
      <div className="white-box logo-box" style={{ background: "none" }}>
        <Row gutter={10}>
          <Col
            span={24}
            sm={12}
            md={12}
            lg={24}
            xl={24}
            xxl={12}
            style={{ background: "white", borderRadius: "7px" }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <img
                  src={uploadedImage}
                  style={{
                    margin: "auto",
                    display: "flex",
                    width: "100%",
                    padding: "20px 30px",
                  }}
                  alt="logo"
                />
              </Col>
              <Col span={24}>
                <Row gutter={10}>
                  <Col span={12}>
                    <Button
                      className="standard-button primary-dark-blue-button"
                      icon={<DeleteFilled />}
                      style={{
                        width: "calc(100% - 5px)",
                      }}
                      onClick={() => deleteLogo()}
                      disabled={roAdmin}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col span={12} style={{ paddingRight: "0px" }}>
                    <Upload
                      customRequest={(e) => uploadFile(e)}
                      accept="image/*"
                    >
                      <Button
                        className="standard-button primary-dark-blue-button"
                        icon={<CloudUploadOutlined />}
                        style={{
                          width: "calc(100% - 5px)",
                        }}
                        disabled={roAdmin}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
