import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Typography,
  Row,
  Form,
  Input,
  Space,
  message,
} from "antd";

import Powerful from "assets/Images/undraw_create_account.svg";
import sdk from "sdk/Accounts";
import { Auth } from "aws-amplify";
import { setAccountDetails, setLoginData } from "store/actions/loginAction";
import Modal from "antd/lib/modal/Modal";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

const NewAccount = ({ expand, select, reset }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    setLoading(true);
    let result = await sdk.createAccount(e.company, e.url, [], false);
    if (result.error) {
      message.error(result.error, 5);
    } else {
      message.success(`Account created successfully!`);
      await fetchUser();
      return;
    }
    setLoading(false);
    reset();
  };

  const fetchUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      async (err, session) => {
        const response = Auth.currentUserInfo();
        response.then((data) => {
          dispatch(setLoginData(session, data?.attributes));
        });
        let cognitoGroups = CurrentUserSessionHelper.getOnlyCustomerCognitoGroups(
          CurrentUserSessionHelper.getCognitoGroupsFromAccessToken(
            session?.accessToken
          )
        );
        if (cognitoGroups?.length > 0) {
          let res = await sdk.fetchAccount(cognitoGroups[0]);
          dispatch(setAccountDetails(res));
          history.push("/");
        }
      }
    );
  };

  return (
    <>
      <Card
        style={{ border: "none" }}
        className="selection-card selection-card-new"
      >
        <Row style={{ justifyContent: "space-around" }}>
          <Col span={6} style={{ display: "flex", justifyContent: "left" }}>
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <img
                src={Powerful}
                alt="new account"
                style={{ width: "100%", maxHeight: "145px" }}
              />
            </div>
          </Col>
          <Col
            span={14}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
            }}
          >
            <Typography.Title
              level={4}
              style={{ textAlign: "left", fontSize: "22px" }}
            >
              Setup A New Company
            </Typography.Title>
            <Typography.Paragraph
              style={{
                fontWeight: "normal",
                fontSize: "16px",
                textAlign: "left",
                display: "flex",
                justifyContent: "left",
                maxWidth: "600px",
              }}
            >
              Select continue to get started if no one at your company has set
              up a Blustream account.
            </Typography.Paragraph>

            <Row>
              <Col span={8}>
                <Button
                  type="primary"
                  block
                  className="standard-button primary-orange-button"
                  onClick={select}
                >
                  <b>Continue</b>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Modal
        className="new-account-model"
        visible={expand}
        closable={true}
        footer={null}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              style={{
                fontSize: 20,
                position: "absolute",
                color: "#ffffff",
                marginLeft: 30,
              }}
              onClick={reset}
            />
          </strong>
        }
      >
        <Row justify="center">
          <Col span={20}>
            <Typography.Title level={5} className="modal-title">
              Create New account
            </Typography.Title>
            <Typography.Text style={{ marginBottom: 20 }}>
              Please fill in the following information for you company so we can
              provision your trial account
            </Typography.Text>

            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
              style={{ paddingTop: 15 }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="company"
                  style={{
                    marginTop: "0px",
                    marginBottom: "12px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of your company",
                    },
                    {
                      pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)/,
                      message: "Please enter valid company name",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    size="large"
                    id="company"
                    key="company"
                    name="company"
                    placeholder="Company Name"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>
                <Form.Item
                  name="url"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the URL of your company",
                    },
                    {
                      pattern:
                        /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                      message: "Please enter valid URL",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    size="large"
                    id="url"
                    key="url"
                    name="url"
                    placeholder="Company URL"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    loading={loading}
                    tabIndex={5}
                    className="standard-button primary-orange-button"
                  >
                    Get Started
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewAccount;
