import * as actions from "store/actions";
import { 
    getBrandingElementsBySubdomain, 
    saveBrandingElements, 
} from "apiService/RESTApi";

// Action Creators
export const setPageSettings = (page) => ({
    type: actions.SET_PAGE,
    payload: page,
});

export const setThemeSettings = (theme) => ({
    type: actions.SET_THEME,
    payload: theme,
});

export const setChatSettings = (chat) => ({
    type: actions.SET_CHAT,
    payload: chat,
});

export const setPwaSettings = (pwa) => ({
    type: actions.SET_PWA,
    payload: pwa,
});

export const setLinksSettings = (links) => ({
    type: actions.SET_LINKS,
    payload: links,
});

// Get data from server
export const fetchBrandingElements = (subdomain) => async (dispatch) => {
  try {
    const brandingResponse = await getBrandingElementsBySubdomain(subdomain);
    dispatch({
      type: actions.SET_BRANDING_ELEMENTS,
      payload: brandingResponse,
    });
    return brandingResponse; 
  } catch (error) {
    console.error("Error fetching branding elements:", error);
    return null;
  }
};

// Update data on server
export const updateBrandingElements = (customerUuid, payload) => async (dispatch) => {
  try {
    await saveBrandingElements(customerUuid, payload);
    dispatch({
      type: actions.UPDATE_BRANDING_ELEMENTS,
      payload,
    });
  } catch (error) {
    console.error("Error updating branding elements:", error);
  }
};