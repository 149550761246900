import React, { useEffect, useState, useRef } from "react";
import "../microsite/microsite.css";
import "../../css/emailTemplate.css";
import { useHistory, useLocation } from "react-router-dom";

import { Editor } from '@tinymce/tinymce-react';
import { Auth } from "aws-amplify";
import { useSpecialOnlyUserCognitoGroups } from "hooks/CognitoGroupHooks";
import { useActiveCompanyUuid } from "hooks/CompanyHooks";
import {
  addCustomerAsset,
  getCustomerAssets,
  sendTestEmail,
  getCustomerId,
  contactSearch,
  getContactbyID,
  deleteCustomerAsset
} from "apiService/RESTApi";
import { Modal, Button, Row, Col, Checkbox, Skeleton, Input, Spin, message, Divider } from "antd";
import { SearchOutlined, PaperClipOutlined, DeleteOutlined } from "@ant-design/icons";
import templateIcon from "assets/Images/templace-icon.svg";
import chatStreamIcon from "assets/Images/chatStream-icon.svg";
import settingIcon from "assets/Images/setting-icon.svg";
import WebchatEmailTemplateTabMenu from "../../Components/Webchat/WebchatEmailTemplateTabMenu";
import WebchatBrandingSaveButton from "../../Components/Webchat/WebchatBrandingSaveButton";
import { setEmailTemplate } from "store/actions/WebchatEmailTemplateAction"; 
import { store } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { fetchEmailTemplates } from 'store/actions/WebchatEmailTemplateAction';
import WebchatTabs from "../../Components/Webchat/WebchatTabs";

const DEFAULT_PATH = '/webchat/email-template';

export default function CustomerEmailTemplate() {
  const [currentContent, setCurrentContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const editorRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const fileInputRef = useRef(null);
  const [assets, setAssets] = useState([]);
  const [assetsModalIsOpen, setAssetsModalIsOpen] = useState(false);
  const openAssetsModal = () => setAssetsModalIsOpen(true);
  const closeAssetsModal = () => setAssetsModalIsOpen(false);
  const [uploadedAsset, setUploadedAsset] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const history = useHistory();
  const activeCompanyUuid = useActiveCompanyUuid();
  const specialOnlyUserCognitoGroups = useSpecialOnlyUserCognitoGroups();

  const [open, setOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactSearchQuery, setContactSearchQuery] = useState("");
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [contactSearchResultsComplete, setContactSearchResultsComplete] = useState(false);
  const contactSearchLoader = useRef(false);
  const listInnerRef = useRef();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [contactSearchPaginationProperties, setContactSearchPaginationProperties] = useState({
    current: 1,
    pageSize: 10,
    totalPages: 0
  });
  const [sortOrder, setSortOrder] = useState('asc');
  let debounceValue = "";

  const location = useLocation();
  const dispatch = useDispatch();
  const {emailTemplates} = useSelector((state) => state.WebchatBrandSettingsReducer);

  const tabItems = [
    { tabKey: "emailTemplates", icon: templateIcon, altText: "Email Templates", label: "Email Templates", route: "/webchat/email-templates" },
    { tabKey: "branding", icon: chatStreamIcon, altText: "Branding", label: "Branding", route: "/webchat/branding" },
    { tabKey: "settings", icon: settingIcon, altText: "Settings", label: "Settings", route: "/webchat/settings" },
  ];


  const previewContent = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      console.log("Editor Content:", content);
      setCurrentContent(content);
    }
  };

  const handleOk = async () => {
    setOpen(false);
    setSelectedContacts([]);

    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    const email = currentAuthenticatedUser?.attributes?.email;
    const customerUuid = activeCompanyUuid;

    const payload = {
      customerUuid: customerUuid,
      customerEmailTemplateId: selectedTemplate.id,
      contactId: selectedContact.value,
      emailAddress: email,
    };
    const response = await sendTestEmail(payload);
    console.log("Test Email Sent", response);

  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedContacts([]);
    setSelectedContact(null);
  };

  const showModal = () => {
    setOpen(true);
  };

  const checkedContacts = (event, contact) => {

    let contacts;
    let id = contact.value;
    if (selectedContacts?.includes(id)) {
      contacts = selectedContacts?.filter((id) => id !== id);
    } else {
      contacts = [...selectedContacts, id];
    }
    setSelectedContacts(contacts);

    if (event.target.checked) {
      setSelectedContact(contact);
    } else {
      setSelectedContact(null);
    }
  };

  const onContactScroll = () => {
    if (contactSearchLoader.current) {
      return;
    }

    if (listInnerRef.current && contactSearchPaginationProperties.current < contactSearchPaginationProperties.totalPages) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight * .98) {

        const nextPage = contactSearchPaginationProperties.current + 1;
        const newContactSearchPaginationProperties = {
          current: nextPage,
          pageSize: contactSearchPaginationProperties.pageSize,
          total: 0,
          totalPages: contactSearchPaginationProperties.totalPages
        };

        updateContactSearchPaginationProperties(newContactSearchPaginationProperties);
        getContactsData(contactSearchQuery, newContactSearchPaginationProperties, false);

      }
    }
  };

  const updateContactSearchLoader = (searchLoaderState) => {
    contactSearchLoader.current = searchLoaderState;
  };
  const updateSearchedContacts = (searchedContactsValue) => {
    setSearchedContacts(searchedContactsValue);
  }
  const updateContactSearchResultsComplete = (contactSearchResultsCompleteValue) => {
    setContactSearchResultsComplete(() => contactSearchResultsCompleteValue);
  };
  const updateContactSearchQuery = (value) => {
    setContactSearchQuery(() => value);
  }
  const updateContactSearchPaginationProperties = (contactSearchPaginationPropertiesValue) => {
    setContactSearchPaginationProperties(() => contactSearchPaginationPropertiesValue);
  }

  const getContactsData = async (contactSearchQuery, paginationState, newSearch) => {
    updateContactSearchLoader(true);

    await getCustomerId(activeCompanyUuid).then(async (res) => {
      const customerArn = res.response.data[0].customerArn;
      const searchResults = await contactSearch(
        customerArn,
        contactSearchQuery,
        paginationState.current,
        paginationState.pageSize
      );

      const promiseArray = searchResults?.data?.map((contact) => {
        return getContactbyID(contact?.contactId);
      });

      Promise.all(promiseArray).then((values) => {
        const contacts = values?.map((item) => ({
          label: `${item?.firstName} ${item?.lastName}`,
          value: item?.contactId
        }));

        if (newSearch) {
          updateSearchedContacts(contacts);
        }
        else {
          updateSearchedContacts([...searchedContacts, ...contacts]);
        }

        updateContactSearchPaginationProperties({
          current: searchResults.page.number + 1,
          pageSize: searchResults.page.size,
          total: searchResults.page.totalElements,
          totalPages: searchResults.page.totalPages
        });

        updateContactSearchLoader(false);

        if ((paginationState.current === searchResults.page.totalPages)
          || (newSearch && contacts.length === 0)) {
          updateContactSearchResultsComplete(true);
        }
        else {
          updateContactSearchResultsComplete(false);
        }

        return contacts;
      });

      updateContactSearchLoader(false);
    })
      .catch((error) => {
        updateContactSearchLoader(false);
        console.log("Error :", error);
        return error;
      });

  };

  const onContactSearch = (value) => {
    updateSearchedContacts([]);
    updateContactSearchResultsComplete(false);
    updateContactSearchQuery(value);
    updateContactSearchLoader(true);

    if (value) {
      value = value.trim();
    }
    if (value.length === 0 || value.length > 2) {
      clearTimeout(debounceValue);
      debounceValue = setTimeout(async () => {

        const newContactSearchPaginationProperties = {
          current: 1,
          pageSize: contactSearchPaginationProperties.pageSize,
          total: 0,
          totalPages: 0
        };

        updateContactSearchPaginationProperties(newContactSearchPaginationProperties);
        getContactsData(value, newContactSearchPaginationProperties, true);

      }, 1000);
    } else {
      clearTimeout(debounceValue);
    }
  };

  useEffect(() => {
    const fetchCustomerEmailTemplates = async () => {
      if (!activeCompanyUuid) return;
      setLoading(true);
      try {
        await dispatch(fetchEmailTemplates(activeCompanyUuid));
        console.log("Templates fetched successfully from Redux!");
        
        const loadedTemplates = await new Promise(resolve =>
          setTimeout(() => {
            const state = store.getState();
            resolve(state.WebchatEmailTemplateReducer.emailTemplates);
          }, 100)
        );
        
        if (loadedTemplates?.length) {
          const updatedSelectedTemplate = loadedTemplates.find(t => t.id === selectedTemplate?.id) || loadedTemplates[0];
          setSelectedTemplate(updatedSelectedTemplate);
          setCurrentContent(updatedSelectedTemplate.body);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      } finally {
        setLoading(false);
        setUnsavedChanges(false);
      }
      setEditorKey(prevKey => prevKey + 1);
    };
  
    fetchCustomerEmailTemplates();
  }, [activeCompanyUuid, dispatch]);

  useEffect(() => {
    if (!specialOnlyUserCognitoGroups.includes("beta")) {
      history.push("/");
    }

    let currentPath = location.pathname
    if (currentPath === "/webchat") {
      history.push(DEFAULT_PATH);
    } else if (currentPath === "/webchat/email-template") {
      setActiveTab('emailTemplates');
    } else if (currentPath === "/webchat/branding") {
      setActiveTab('branding');
    } else if (currentPath === "/webchat/settings") {
      setActiveTab('settings');
    }

    const fetchInitialContacts = async () => {
      const initialPaginationProperties = {
        current: 1,
        pageSize: 10,
        totalPages: 0
      };
      await getContactsData("", initialPaginationProperties, true);
    };

    fetchInitialContacts();
  }, []);

  const handleTemplateClick = (template) => {
    setCurrentContent(template.body);
    setSelectedTemplate(template);
  };

  const handleFileChange = () => {
    setFileSelected(fileInputRef.current.files.length > 0);
  };

  const handleImageUpload = async () => {
    setLoading(true);
    const file = fileInputRef.current.files[0];

    if (file) {
      setLoading(true);
      const asset = await addCustomerAsset(activeCompanyUuid, file);
      fileInputRef.current.value = '';
      setUploadedAsset(asset);
      setLoading(false);
      setFileSelected(false);

      // Update the assets list with the new asset
      setAssets((prevAssets) => [...prevAssets, asset]);
    }
  };

  const viewCustomerAssets = async () => {
    setLoading(true);
    const data = await getCustomerAssets(activeCompanyUuid);
    // console.log(data.response.data[0]);

    if (data.response.data !== null) {
      const assets = data.response.data[0];
      setAssets(assets);
    }
    setLoading(false);
    openAssetsModal();
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    message.success("Url copied to clipboard!");
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const sortAssets = () => {
    const sortedAssets = [...assets].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setAssets(sortedAssets);
    toggleSortOrder();
  };

  const handleEditorChange = () => {
    if (selectedTemplate === null) return;

    if (selectedTemplate.body === editorRef.current.getContent()) {
      setUnsavedChanges(false);
      return;
    } 
    
    setUnsavedChanges(true);
    const template = {
      templateId: selectedTemplate.id,
      name: selectedTemplate.name,
      subject: selectedTemplate.subject,
      body: editorRef.current.getContent()
    };
    dispatch(setEmailTemplate(template));

    if (editorRef.onEditorChange) {
      previewContent();
    }

  };

  const deleteCustomerAssetById = async (assetId) => {
    setLoading(true);
    const response = await deleteCustomerAsset(assetId);

    if (response.success === true) {
      const updatedAssets = assets.filter(asset => asset.customerAssetId !== assetId);
      setAssets(updatedAssets);
      message.success("Asset deleted successfully!");
    } else {
      message.error("Error deleting asset!");
    }
    setLoading(false);
  };

  const templateCardBody = (title) => {
    switch (title) {
      case 'Welcome':
        return 'Contacts receive this message as soon as they are eligible for a web-enabled journey. Use this email to get your customers excited to use this channel in conjunction to their purchased product. If there are pre-purchase preparation steps for your product, you can hint at them here as well.';
      case 'New Message':
        return 'Whenever a contact receives a new message while they are not using web chat, the customer is sent this email with a link to go to web chat directly.';
      case 'New Magic Link':
        return 'This message is sent when the Contact requests a new Magic Link. It includes a link which will log them in automatically when clicked.';
      default:
        return '';
    }
  }

  function dismissToolbarMenu() {
    const toolbarMenu = document.querySelector('.tox-tbtn[aria-label="Reveal or hide additional toolbar items"]');
    if (toolbarMenu) {
      toolbarMenu.click();
    }
  }

  return (
    <Spin spinning={loading} tip="Loading templates..." size="medium">
      <div className="email-template-container">
      {/* Header Section */}
      <div className="editor-header">
        <div className="editor-header-left">
          <h2><strong>Web Chat</strong></h2>
          <p className="subheading">Easily connect with your contacts in a web-based, mobile-enabled channel.</p>
        </div>
        <div className="editor-header-right">
          <div className="editor-header-right">
              <WebchatBrandingSaveButton  unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} loading={() => {
                  setLoading(true);
                  setUnsavedChanges(false);
                  setTimeout(() => setLoading(false), 1000);
                  return true;
                }}
              />
          </div>
          <button className="preview-button" hidden={true}>Preview</button>
          <div>
          
            <Modal
              open={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Upload Image"
              className="modal"
              overlayClassName="overlay"
              okText="Done"
              cancelButtonProps={{ style: { display: 'none' } }}
              onOk={() => {
                fileInputRef.current.value = '';
                setUploadedAsset(null);
                setLoading(false);
                setFileSelected(false);
                closeModal();
              }}
            >
              <h2>Upload Assets</h2>
              <input type="file" accept="image/*" ref={fileInputRef} onChange={handleFileChange} disabled={loading} />
              {fileSelected && <button onClick={handleImageUpload} disabled={loading}>Upload</button>}
              {loading && <Spin style={{ marginLeft: 10 }} />}
              {uploadedAsset && (
                <div className="uploaded-asset">
                  <span className="asset-url">{uploadedAsset.name}</span>
                  <Button onClick={() => copyToClipboard(uploadedAsset.url)} className="copy-button">
                    <PaperClipOutlined />
                  </Button>
                </div>
              )}
            </Modal>
          </div>

          <Modal
            open={assetsModalIsOpen}
            onRequestClose={closeAssetsModal}
            contentLabel="Customer Assets"
            className="modal"
            overlayClassName="overlay"
            shouldCloseOnOverlayClick={false}
            closeIcon={true}
            okText="Upload"
            onOk={() => {
              openModal();
            }}
            cancelText="Done"
            onCancel={() => {
              closeAssetsModal();
            }}
          >
            <div className="assets-header">
              <h2>Assets</h2>
              <Button className="sort-button" onClick={sortAssets}>
                Sort by Name ({sortOrder === 'asc' ? 'Asc' : 'Desc'})
              </Button>
            </div>
            <hr></hr>
            <ul className="assets-list">
              {assets.map((asset, index) => (

                <li key={index} className="asset-item">
                  <Button
                    className="delete-button"
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => deleteCustomerAssetById(asset.customerAssetId)}
                  />
                  <img src={asset.url} alt="" className="asset-image" />
                  <span className="asset-url">{asset.name}</span>
                  <button onClick={() => copyToClipboard(asset.url)} className="copy-button">
                    <PaperClipOutlined />
                  </button>
                </li>
              ))}
            </ul>
          </Modal>
        </div>
      </div>
      <Divider style={{ marginTop: '20px' }} />
      <div className="main-container">
        {/* Editor Section */}
        <div className="editor-main-container">
          <div className="editor-container">
            <Editor
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              onInit={(_evt, editor) => editorRef.current = editor}
              key={editorKey}
              initialValue={currentContent}
              init={{
                branding: true,
                content_css: [

                ],
                height: '100%',
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                ],
                menubar: true,
                toolbar: 'undo redo | blocks | fontsizeinput | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | customViewAssets | testButton',
                setup: (editor) => {
                  editor.ui.registry.addButton('customViewAssets', {
                    text: 'View Assets',
                    onAction: () => {
                      viewCustomerAssets();
                      dismissToolbarMenu();
                    }
                  });

                  editor.ui.registry.addButton('testButton', {
                    text: 'Send Test Email',
                    onAction: () => {
                      showModal();
                      dismissToolbarMenu();
                    }
                  });
                },
                content_style: `
                  body {
                    font-family: Arial, sans-serif; 
                    font-size: 14px; 
                    text-align: center; 
                    margin: 0 auto; 
                    max-width: 600px; 
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                  }
                  img {
                    max-width: 100%;
                    height: auto;
                  }
                  p {
                    margin: 20px 0;
                  }
                `,
              }}
              onEditorChange={handleEditorChange}
            />
          </div>
        </div>

        {/* Templates Section */}
        <div className="templates-list-header-container">
          {/* Tabs Header */}
            <WebchatTabs activeTab={activeTab} tabItems={tabItems} />
          {/* Tab Content */}
          <div className="template-list-container">

            {activeTab === 'emailTemplates' && (
              <WebchatEmailTemplateTabMenu
                templates={emailTemplates}
                selectedTemplate={selectedTemplate}
                handleTemplateClick={handleTemplateClick}
                templateCardBody={templateCardBody}
                currentContent={currentContent}
              />
            )}

          </div>
        </div>
      </div>
      <Modal
        className="journey-test-modal"
        open={open}
        title={
          <span
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "22px",
              color: "#252525",
            }}
          >
            Choose Contact
          </span>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <p
            style={{
              textAlign: "start",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "19px",
              color: "#959595",
              marginBottom: "10px",
            }}
          >
            Pressing Send Email will format and personalize a sample email intended for {selectedContact === null ? 'Contact' : selectedContact.label}. The sample email will be sent to the email on file for your account. Please check your spam folder too.{" "}
            <span style={{ color: "#252525" }}>Click send</span> to confirm.
          </p>,
          <Button
            key="1"
            type={selectedContacts.length > 0 ? "primary" : ""}
            className="standard-button primary-orange-button"
            style={{
              width: "100%",
            }}
            onClick={() => {
              handleOk();
            }}
            disabled={selectedContacts.length <= 0}
          >
            Send
          </Button>,
        ]}
        centered
      >
        <div>
          <Input.Search
            type="search"
            className="standard-searchbox"
            allowClear
            prefix={<SearchOutlined className="standard-searchbox-prefix" />}
            placeholder="Search Contact Name"
            style={{ display: "block", height: "42px" }}
            onChange={(e) => onContactSearch(e.target.value)}
          />
        </div>
        <div onScroll={onContactScroll} ref={listInnerRef} style={{ height: "40vh", overflow: "scroll" }}>
          <Row gutter={[0, 16]} style={{ padding: "20px 0px" }}>
            {searchedContacts?.map((contact) => {
              return (
                <Col key={contact.value} span={24} style={{ padding: "0px" }}>
                  <Checkbox
                    value={contact.value}
                    onChange={(event) => checkedContacts(event, contact)}
                    checked={selectedContacts?.includes(contact.value)}
                  >
                    {contact.label}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
          <div name="contact-search-loader" style={contactSearchLoader.current ? { display: 'block', padding: "0 0 0 50%" } : { display: 'none' }}>
            <Skeleton.Avatar
              active
              size="default"
              shape="circle"
            />
          </div>
          <div name="no-more-contacts-found" style={contactSearchResultsComplete ? { display: 'block' } : { display: 'none' }}>
            No more contacts to view {contactSearchQuery.trim().length > 3 ? "for search '" + contactSearchQuery + "'" : ""}
          </div>
        </div>
      </Modal>
      </div>
    </Spin>
  );
}