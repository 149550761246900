import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useActiveCompanyUuid } from "hooks/CompanyHooks";
import { updateBrandingElements } from "store/actions/WebchatBrandSettingsAction";
import { saveTemplate ,fetchEmailTemplates } from "store/actions/WebchatEmailTemplateAction";
import { Button } from "antd";

export default function WebchatBrandingSaveButton({ unsavedChanges, setUnsavedChanges, loading }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const activeCompanyUuid = useActiveCompanyUuid();
  
  const { page, emailTemplate } = useSelector((state) => state.WebchatBrandSettingsReducer);

  const handleSave = async () => {
    const stopLoading = loading?.();
    if (!unsavedChanges) {
      console.log("No changes to save.");
      stopLoading?.();
      return;
    }
    let payload = {};

    try {
      switch (location.pathname) {
        case "/webchat/email-template":
          await saveEmailTemplate(activeCompanyUuid, emailTemplate, dispatch);
          break;
        case "/webchat/branding":
          await saveBrandingChanges(page, dispatch, activeCompanyUuid, setUnsavedChanges);
          break;
        case "/webchat/settings":
          payload = { type: "settings", content: page };
          break;
        default:
          console.error("No matching route found for saving.");
          return;
      } 
    } finally {
      stopLoading?.();
      setUnsavedChanges(false);
    }
  };

  return (
    <Button 
      type={unsavedChanges ? "primary" : "default"} 
      onClick={handleSave}
      disabled={!unsavedChanges}
    >
      Save
    </Button>
  );
}

async function saveBrandingChanges(payload, dispatch, activeCompanyUuid, setUnsavedChanges) {
    try {
        await dispatch(updateBrandingElements(activeCompanyUuid, payload));
        console.log(`${payload.type} settings updated successfully`);
        setUnsavedChanges(false);
    } catch (error) {
        console.error(`Error updating ${payload.type} settings:`, error);
    }
}

async function saveEmailTemplate(activeCompanyUuid, emailTemplate, dispatch) {
  try {
    const payload = {
      customerUuid: activeCompanyUuid,
      templateId: emailTemplate.templateId,
      name: emailTemplate.name,
      subject: emailTemplate.subject,
      body: emailTemplate.body
    };
    const response = await dispatch(saveTemplate(payload));
    console.log("Email template saved successfully!");
  } catch (error) {
    console.error("Error saving email template:", error);
  }
}
