import React from 'react';
import WebchatEmailTemplateTabMenuItemPreview from './WebchatEmailTemplateTabMenuItemPreview';

const WebchatEmailTemplateTabMenu = ({ templates, selectedTemplate, handleTemplateClick, templateCardBody, currentContent }) => (
  <div>
    {templates.map((template) => (
      <div
        key={template.id}
        className={`template-card ${selectedTemplate && selectedTemplate.id === template.id ? 'selected' : ''}`}
        onClick={() => handleTemplateClick(template)}>
        <div className="template-content">
          <p className="template-title">{template.name}</p>
          <p className="template-body">{templateCardBody(template.name)}</p>
        </div>
        <div className="template-preview-container">
          <WebchatEmailTemplateTabMenuItemPreview htmlContent={selectedTemplate && selectedTemplate.id === template.id ? currentContent : template.body} />
        </div>
      </div>
    ))}
  </div>
);

export default WebchatEmailTemplateTabMenu;