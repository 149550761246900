import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import SettingReducer from './SettingReducer';
import WebchatBrandSettingsReducer from './WebchatBrandSettingsReducer';
import WebchatEmailTemplateReducer from './WebchatEmailTemplateReducer';


const appReducer = combineReducers({
  loginReducer,
  SettingReducer,
  WebchatBrandSettingsReducer,
  WebchatEmailTemplateReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = { };
  }
  return appReducer(state, action);
};

export default rootReducer;
