import React from "react";
import { Row } from "antd";
import ColorPicker from "../Common/ColorPicker";
import { useSelector, useDispatch } from "react-redux";
import { setPageSettings } from "store/actions/WebchatBrandSettingsAction";
import WebchatBrandingTabMenuIcon from "./WebchatBrandingTabMenuIcon.js";


export default function WebchatBrandingTabMenuChat({ onDataChange = () => {} }) {
    const dispatch = useDispatch();
    const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);

    const onBrandMessageBackgroundColor = (newValue) => {
        dispatch(setPageSettings({ ...page, brandMessageBackgroundColor: newValue }))
        onDataChange()
    };

    const onBrandMessageTextColor = (newValue) => {
        dispatch(setPageSettings({ ...page, brandMessageTextColor: newValue }))
        onDataChange()
    };

    const onContactMessageBackgroundColor = (newValue) => {
        dispatch(setPageSettings({ ...page, contactMessageBackgroundColor: newValue }))
        onDataChange()
    };

    const onContactMessageTextColor = (newValue) => {
        dispatch(setPageSettings({ ...page, contactMessageTextColor: newValue }))
        onDataChange()
    };

    return (
        <> 
            <Row gutter={[10, 10]} style={{ marginLeft: 10 }}>
                <div style={{ marginTop: 20 }}>
                    <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>Branding Message Icon</h3>
                    <WebchatBrandingTabMenuIcon icon={"brandMessageIcon"}  onDataChange={onDataChange}/>
                </div>
                <ColorPicker label="Message Background Color" initialValue={page?.brandMessageBackgroundColor} onChange={(color) => onBrandMessageBackgroundColor(color)} />
                <ColorPicker label="Message Text Color" initialValue={page?.brandMessageTextColor} onChange={(color) => onBrandMessageTextColor(color)} />
                <ColorPicker label="Contact Message Background Color" initialValue={page?.contactMessageBackgroundColor} onChange={(color) => onContactMessageBackgroundColor(color)} />
                <ColorPicker label="Contact Message Text Color" initialValue={page?.contactMessageTextColor} onChange={(color) => onContactMessageTextColor(color)} />
            </Row>
        </>
    );
}
