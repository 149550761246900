import * as actions from "store/actions";
import { v4 as uuidv4 } from "uuid";
import YourLogo from "./../../assets/Images/YourLogo.png";

const initialState = {
  contacts: [],
  loading: false,
  touchpoint: "",
  termAndConditions: {},
  Tags: [],
  micrositeDefaultStyle: {
    micrositeName: "Write Your Form Title Here...",
    micrositePageTitle: "Untitled Form",
    themeStyle: {
      backgroundColor: "#CDE1F3",
      secondaryBackgroundColor: "#ffffff",
      backgroundImageUrl: "",
      backgroundWidth: "100",
      backgroundHeight: "100",
    },
    logoUrl: `${YourLogo}`,
    logoHeight: "30px",
    logoWidth: "auto",
    headingStyle: {
      fontFamily: "arial",
      fontSize: "23px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    subHeadingStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    textStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    questionStyle: {
      fontFamily: "arial",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    answerStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    descriptionStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    fieldStyle: {
      backgroundColor: "#ffffff",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "7px",
    },
    buttonStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      backgroundColor: "#ffffff",
      color: "#000000",
      height: "auto",
      borderColor: "#000000",
    },
    companyTextStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#000000",
    },
    errorMessageColor: "#ff0000",
    successMessage: "Congrats and we can't wait for your journey to start!",
    formLanguage: "en",
    permenantHideOnSubmit: false,
    temporaryHideOnPopupClose: false,
    numberOfHidingDays: 1,
  },
  micrositeFormElements: [
    {
      id: uuidv4(),
      htmlType: "",
      type: "image",
      src: "link",
      style: {
        display: "flex",
        justifyContent: "center",
        margin: "auto auto 10px",
      },
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "heading",
      headingText: "Let's get started!",
      style: { marginTop: 10 },
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "sub-heading",
      headingText: "Your sub heading goes here!",
      style: { marginBottom: 15 },
    },
    {
      id: uuidv4(),
      htmlType: "text",
      type: "default-input",
      label: "First Name",
      placeholder: "Enter first name",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "text",
      type: "default-input",
      label: "Last Name",
      placeholder: "Enter last name",
      style: {},
    },

    {
      id: uuidv4(),
      htmlType: "tel",
      type: "default-input",
      label: "Phone No.",
      placeholder: "Enter phone number",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "email",
      type: "default-email",
      label: "Email",
      placeholder: "Enter email",
      isRequired: false,
      style: {},
    },
    // {
    //   id: uuidv4(),
    //   htmlType: "",
    //   type:"checkbox",
    //   checkboxText:`By signing up, you agree with our Terms & Conditions and Privacy Policy.`,
    //   style: {},
    // },
    {
      id: uuidv4(),
      htmlType: "",
      type: "button",
      buttonText: "Send",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "Consent-text",
      textValue: `By submitting this form, you consent to receive marketing text messages from {{COMPANY}} via an automatic telephone dialing system and that the number you have provided is your own. Consent is not a condition of any purchase. Standard message and data rates may apply. At any time, you can unsubscribe from all messages by replying STOP. View our Privacy Policy and Terms of Service.`,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "copyright",
      textValue: `© ${new Date().getFullYear} TKL - World-Class Cases.`,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "company-text",
      textValue:
        "Sign up today to start your product journey via SMS with [company name]. Get useful tips on the care, maintenance, and enjoyment around your products.",
      style: {},
    },
  ],
  termAndConditionsShown: true,
  privacyPolicyShown: true,
  templateName: "TWO_COLUMN_LEFT_FORM",
  micrositeId: "",
  currentFormElementId: "",
  activeKey: "1",
  logoKey: false,
  companyData: [],
  disabled: true,
};

const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case actions.SHOW_LODER:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.TOUCHPOINT_DATA:
      return {
        ...state,
        touchpoint: action.payload,
      };
    case actions.SET_TNC:
      return {
        ...state,
        termAndConditions: action.payload,
      };
    case actions.SET_TAGS:
      return {
        ...state,
        Tags: action.payload,
      };
    case actions.SET_MICROSITE_DEFAULT_STYLE:
      return {
        ...state,
        micrositeDefaultStyle: action.payload,
      };
    case actions.SET_MICROSITE_FORM_ELEMENTS:
      return {
        ...state,
        micrositeFormElements: action.payload,
      };

    case actions.SET_TEMPLATE_NAME:
      return {
        ...state,
        templateName: action.payload,
      };
    case actions.SET_MICROSITE_ID:
      return {
        ...state,
        micrositeId: action.payload,
      };

    case actions.SET_DISABLED:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.SET_MICROSITE_FORM_ELEMENT_ID:
      return {
        ...state,
        currentFormElementId: action.payload,
      };
    case actions.SET_MICROSITE_ACTIVE_KEY:
      return {
        ...state,
        activeKey: action.payload,
      };
    case actions.SET_LOGO_KEY:
      return {
        ...state,
        logoKey: action.payload,
      };
    case actions.SET_PRIVACYPOLICY:
      return {
        ...state,
        privacyPolicy: action.payload,
      };
    case actions.SET_TNCSHOW:
      return {
        ...state,
        termAndConditionsShown: action.payload,
      };
    case actions.SET_PRSHOW:
      return {
        ...state,
        privacyPolicyShown: action.payload,
      };
    case actions.SET_DATA:
      return {
        ...state,
        companyData: action.payload,
      };

    default:
      return state;
  }
};

export default SettingReducer;
