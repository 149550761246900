/**
 * Orders the cognito groups which typically are recieved from cognito in alphabetical order.
 * A lot of the frontend logic depends on the customer groups being first.
 * 
 * @param {Array} groups - The groups IDs to order.
 * @returns {Array} - The ordered group IDs.
 */
const orderCognitoGroups = (groups) => {
    if (!groups) {
        return [];
    }
    // order matters (ie. beta should be after customer uuids, internal should be last)
    const specialGroups = ["beta", "admin", "internal"];

    // If special group exists, remove and add to end
    specialGroups.forEach(group => {
        if (groups.includes(group)) {
            groups.push(groups.splice(groups.indexOf(group), 1)[0]);
        }
    });

    // group[0] will now be a customers cognito group UUID
    // Groups before special groups will be the customer groups
    // Last groups will be the special groups
    return groups;
};

/**
 * Extracts the customer groups from the cognito groups.
 * @param {Array} groups - The cognito groups.
 * @returns {Array} - The customer groups.
 */
const getOnlyCustomerCognitoGroups = (groups) => {
    if (!groups) {
        return [];
    }
    return groups.filter(group => group.length === 36);
}

/**
 * Returns whether the access token has cognito groups.
 * @param {String} accessToken 
 * @returns {Boolean} - True if the access token has cognito groups, false otherwise.
 */
const accessTokenHasCognitoGroups = (accessToken) => {
    return accessToken && accessToken.payload && accessToken.payload['cognito:groups'];
}

/**
 * Checks if the user's cognito groups include the given group.
 * @param {Array} userCognitoGroups - The user's cognito groups.
 * @param {String} group - The group to check.
 * @returns {Boolean} - True if the user's cognito groups include the given group, false otherwise.
 */
const userCognitoGroupsInclude = (userCognitoGroups, group) => {
    if (!userCognitoGroups || !group) {
        return false;
    }
    return userCognitoGroups.includes(group);
}

/**
 * Gets the cognito groups from the access token.
 * @param {String} accessToken - The access token.
 * @returns {Array} - The cognito groups.
 */
const getCognitoGroupsFromAccessToken = (accessToken) => {
    if (accessTokenHasCognitoGroups(accessToken)) {
        let groups = accessToken.payload['cognito:groups'];
        return orderCognitoGroups(groups);
    }
    return [];
}

export const CurrentUserSessionHelper = {
    orderCognitoGroups,
    getOnlyCustomerCognitoGroups,
    accessTokenHasCognitoGroups,
    userCognitoGroupsInclude,
    getCognitoGroupsFromAccessToken
};