// import external modules
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import FullPageLayout from "containers/FullPageLayout";
import { Modal, message } from "antd";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getCompanyInviteHistory } from "graphql/queries";
import groupIcon from "./../assets/Images/groupIcon.svg";
import {
  setAccountDetails,
  updateCompanyAccount,
  updateUserGroups,
} from "store/actions/loginAction";
import sdk from "sdk/Accounts";
import { setTouchpoint } from "store/actions/SettingAction";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

class AccountLayoutRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestDetails: null,
      loader: false,
      showErrorModal: false,
      showRequestNotFoundModal: false,
    };
  }

  async componentDidMount() {
    let { location, userData } = this.props;

    if (!this.props.token && !location?.search?.length > 0) {
      message.error("Please login");
    }
    if (location?.search?.length > 0) {
      this.setState({
        loader: true,
      });
      try {
        const requestId = location?.search?.split("?requestid=")[1];
        const invitation = await API.graphql(
          graphqlOperation(getCompanyInviteHistory, { id: requestId })
        );
        console.log(invitation, invitation?.data?.getCompanyInviteHistory, 43);
        this.setState({
          requestDetails: invitation?.data?.getCompanyInviteHistory,
        });
        if (this.state.requestDetails) {
          if (this.state.requestDetails?.email === userData?.email) {
            //add to company and send to home
            const apiName = "blustreamREST";
            const path = "/accounts/join-organisation";
            const body = {
              id: this.state.requestDetails?.id,
            };
            await API.post(apiName, path, { body: body });
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(
              currentSession.refreshToken,
              async (err, session) => {
                const { accessToken } = session;
                const response = Auth.currentUserInfo();
                response.then((data) => {
                  this.props.updateUserGroups(session, data?.attributes);
                });
                if (CurrentUserSessionHelper.accessTokenHasCognitoGroups(accessToken)) {
                  const selectedOrganizationDetail = await sdk.fecthCompanies(
                    this.state.requestDetails?.account
                  );
                  this.props.updateCompanyAccount(
                    selectedOrganizationDetail?.data?.getCustomerAccount
                  );
                  this.props.setTouchpoint("");
                  message.success("Joined organization successfully!");
                  if (selectedOrganizationDetail?.data?.getCustomerAccount) {
                    const account = await sdk.fetchAccount(
                      selectedOrganizationDetail?.data?.getCustomerAccount?.id
                    );
                    this.props.setAccountDetails(account);
                  }

                  window.location.href = "/";
                  this.setState({
                    loader: false,
                  });
                }
              }
            );
          } else {
            //show pop up
            this.setState({
              loader: false,
            });
            this.setState({ showErrorModal: true });
          }
        } else {
          //show pop up
          this.setState({
            loader: false,
          });
          this.setState({ showRequestNotFoundModal: true });
        }
      } catch (error) {
        console.log(error);
        this.setState({
          loader: false,
        });
      }
    }
  }

  render() {
    let { token, groups, render, verificationDetails, ...rest } = this.props;
    groups = groups?.filter((item) => item);
    const storeDetails = localStorage.getItem("storeDetails");
    return (
      <Route
        {...rest}
        render={(matchProps) => {
          return (
            <Fragment>
              <Modal
                title={null}
                visible={this.state.showErrorModal}
                footer={null}
                className="invitation-modal"
                centered
                onCancel={() => {
                  this.setState({ showErrorModal: false });
                  this.props.history.push("/");
                }}
              >
                <div className="invitation-modal-content-wrapper">
                  <img src={groupIcon} alt="groupIcon" />
                  <p className="invitation-title">
                    You are ineligible for this invite. Please log in with the
                    invitee's account.
                  </p>
                </div>
              </Modal>
              <Modal
                width={300}
                title={null}
                visible={this.state.showRequestNotFoundModal}
                footer={null}
                className="invitation-modal"
                centered
                onCancel={() => {
                  this.setState({ showRequestNotFoundModal: false });
                  this.props.history.push("/");
                }}
              >
                <div className="invitation-modal-content-wrapper">
                  <img src={groupIcon} alt="groupIcon" />
                  <p className="invitation-title">Request not found!</p>
                  <p>It might have been deleted or already accepted.</p>
                </div>
              </Modal>
              {this.state.loader && <div className="loading">Loading;</div>}
              {!token && <Redirect from={matchProps.path} to={"/signin"} />}
              {!token && matchProps?.location?.search?.length > 0 && (
                <Redirect from={matchProps.path} to={"/signup"} />
              )}

              {token &&
                groups.length === 0 &&
                !storeDetails &&
                Object.keys(verificationDetails)?.length <= 0 &&
                !matchProps?.location?.search?.length > 0 && (
                  <Redirect from={matchProps.path} to="/setup" />
                )}

              {token && !matchProps?.location?.search?.length > 0 && (
                <FullPageLayout>{render(matchProps)}</FullPageLayout>
              )}
            </Fragment>
          );
        }}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    verificationDetails: state.loginReducer.verificationDetails,
    userData: state.loginReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAccountDetails: (account) => dispatch(setAccountDetails(account)),
    updateCompanyAccount: (account) => dispatch(updateCompanyAccount(account)),
    updateUserGroups: (session, data) =>
      dispatch(updateUserGroups(session, data)),
    setTouchpoint: (metaData) => dispatch(setTouchpoint(metaData)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountLayoutRoute)
);
