import { useSelector } from 'react-redux';
import { getCustomerId } from "apiService/RESTApi";

export const useAllUserCompanyData = () => {
    const companyData = useSelector((state) => state.SettingReducer.companyData);
    return companyData.filter(
        (item) => item?.data?.getCustomerAccount !== null && !item?.errors
    );
};

export const useActiveCompanyName = () => {
    const activeCompany = useActiveCompanyData();
    return activeCompany?.company;
};

export const useActiveCompanyData = () => {
    return useSelector((state) => state.loginReducer.accountDetails);
};

export const useActiveCompanyUuid = () => {
    const activeCompany = useActiveCompanyData();
    return activeCompany?.id;
};

export const useActiveCompanyAlias = async () => {
    const response = await getCustomerId(useActiveCompanyUuid());
    return response?.response.data[0]?.alias
};