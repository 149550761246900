import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Button } from "antd";
import { useSelector } from "react-redux";
import { setTouchpoint } from "store/actions/SettingAction";
import { useDispatch } from "react-redux";
import {
  updateCompanyAccount,
  setAccountDetails,
  setRoAdmin,
} from "store/actions/loginAction";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

import deleteIcon4 from "./../assets/Images/deleteIcon4.svg";

import sdk from "sdk/Accounts";
import { CheckOutlined } from "@ant-design/icons";

const DropDownMenu = (props) => {
  const {
    handleSignOut,
    path,
    inviteHistory,
    acceptInvitation,
    invitedOrganizationDetails,
  } = props;
  const history = useHistory();
  const cognitoGroups = useSelector((state) => state.loginReducer.groups);
  const [selectedKey, setselectedKey] = useState("");
  const { companyData } = useSelector((state) => state.SettingReducer);
  // eslint-disable-next-line
  const [acceptInvitationLoader, setAcceptInvitationLoader] = useState(false);
  const dispatch = useDispatch();

  const companyData1 = companyData.filter(
    (item) => item?.data?.getCustomerAccount !== null && !item?.errors
  );

  useEffect(() => {
    const menukeys = ["/admin", "/settings"];
    if (menukeys.includes(path)) {
      setselectedKey(path?.split("/")[1]);
    } else {
      setselectedKey("");
    }
  }, [path]);

  const selectCompany = async (accountId, from) => {
    if (from === "admin") {
      dispatch(updateCompanyAccount(accountId?.Company_Data));

      dispatch(setTouchpoint(""));
    } else {
      dispatch(updateCompanyAccount(accountId));
      dispatch(setTouchpoint(""));
    }

    if (accountId) {
      let account = await sdk.fetchAccount(accountId?.id);
      dispatch(setAccountDetails(account));
    }
    window.location.href = "/";
  };

  return (
    <Menu
      style={{
        width: "258px",
        borderRadius: "6px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
      }}
      className="user-menu-container"
      selectedKeys={[selectedKey]}
      onClick={() => props.closeDropdown()}
    >
      {inviteHistory?.length > 0 && (
        <Menu.Item key="invitation" className="invitation-menu">
          <div>
            <div className="invite-decline-container">
              <p className="invitation-text">
                You have received invitation to join{" "}
                <span style={{ fontStyle: "italic" }}>
                  {invitedOrganizationDetails?.company}
                </span>
              </p>
              <img
                src={deleteIcon4}
                alt="deleteIcon"
                style={{ marginTop: "5px" }}
                onClick={() => props.showDeclineInviteModal()}
              />
            </div>
            <div className="menu-invite-buttons-container">
              <Button
                loading={acceptInvitationLoader}
                className="standard-button primary-orange-button"
                onClick={async () => {
                  setAcceptInvitationLoader(true);
                  await acceptInvitation(inviteHistory?.[0]?.id);
                  setAcceptInvitationLoader(false);
                }}
                style={{ width: "110px" }}
              >
                Accept
              </Button>
              <Button
                className="standard-button secondary-orange-button"
                style={{ width: "110px" }}
                onClick={() => {
                  inviteHistory?.length > 1
                    ? history.push({
                        pathname: "/settings",
                        state: { key: "1", subKey: "2" },
                      })
                    : props.showInvitationModal();
                }}
              >
                {inviteHistory?.length > 1 ? "View all" : "View"}
              </Button>
            </div>
          </div>
        </Menu.Item>
      )}
      {(CurrentUserSessionHelper.userCognitoGroupsInclude(cognitoGroups, "admin") || CurrentUserSessionHelper.userCognitoGroupsInclude(cognitoGroups, "roadmin")) &&
        (
          <>
            <Menu.Item key="admin" className="user-menu user-menu-item">
              <Link to={"/admin"}>Admin</Link>
            </Menu.Item>
          </>
        )
      }
      <Menu.Item
        key="settings"
        className="user-menu user-menu-item"
        onClick={() => props.closeDropdown()}
      >
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      {companyData1.length > 1 ? (
        <Menu.Item key="org-title" className="oraganization-title">
          <div className="org-title">Your Organizations</div>
        </Menu.Item>
      ) : (
        ""
      )}
      {companyData1.map((item) => {
        return companyData1.length > 1 ? (
          <Menu.Item
            key={item?.data?.getCustomerAccount?.id}
            className="user-menu"
            onClick={() => {
              selectCompany(item?.data?.getCustomerAccount);
              props.closeDropdown();
              dispatch(setRoAdmin(false));
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="user-menu-item"
                style={{ wordBreak: "break-all", whiteSpace: "break-spaces" }}
              >
                {item?.data?.getCustomerAccount?.company}
              </div>
              {cognitoGroups[0] === item?.data?.getCustomerAccount?.id && (
                <CheckOutlined style={{ color: "#E24B4C" }} />
              )}
            </div>
          </Menu.Item>
        ) : (
          ""
        );
      })}
      <Menu.Divider style={{ border: "0.5px solid #E0E0E0" }} />
      <Menu.Item
        key="logout"
        className="user-menu"
        onClick={() => props.closeDropdown()}
      >
        <div className="logout-menu" onClick={handleSignOut}>
          Log out
        </div>
      </Menu.Item>
    </Menu>
  );
};

export default DropDownMenu;
