import React from "react";
import {
    Row,
} from "antd";
import ColorPicker from "../../Components/Common/ColorPicker";
import TextField from "../Common/TextField";
import { useSelector, useDispatch } from "react-redux";
import { setPageSettings } from "store/actions/WebchatBrandSettingsAction";
import WebchatBrandingTabMenuIcon from "./WebchatBrandingTabMenuIcon";

const defaultmainElementColor = '#000';
const defaultbuttonBackgroundColor = '#fff';
const defaultbuttonTextColor = "#000";


export default function WebchatBrandingTabMenuTheme({ onDataChange = () => { } }) {
    const dispatch = useDispatch();
    const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);

    const onChangeMainElementColor = (newValue) => {
        // let updatedTheme = { ...theme, mainElementColor: newValue };
        // dispatch(setThemeSettings(updatedTheme));
        dispatch(setPageSettings({ ...page, mainElementColor: newValue }))
        onDataChange()
    };

    const onChangeButtonTextColor = (newValue) => {
        // let updatedTheme = { ...theme, buttonTextColor: newValue };
        // dispatch(setThemeSettings(updatedTheme));
        dispatch(setPageSettings({ ...page, buttonTextColor: newValue }))
        onDataChange()
    };

    return (
        <div className="ant-collapse-content-box">
            <p>The colors below are used across prominent buttons, switches, and menu icons.</p>

            <Row gutter={[10, 10]} style={{ marginLeft: 10 }}>
                <ColorPicker label="Main Element Color" initialValue={page?.mainElementColor || defaultmainElementColor} onChange={(color) => onChangeMainElementColor(color)} />
                <ColorPicker label="Button Text Color" initialValue={page?.buttonTextColor || defaultbuttonTextColor} onChange={(color) => onChangeButtonTextColor(color)} />
                <TextField
                    label="Page Title"
                    onChange={(value) => {
                        dispatch(setPageSettings({ ...page, title: value }));
                        onDataChange();
                    }}
                    placeholder={page.title || "Web Chat"}
                />
                <div style={{ marginTop: 20 }}>
                    <h3 style={{ marginBottom: 0, fontWeight: "bold" }}>Page Header Icon</h3>
                    <WebchatBrandingTabMenuIcon icon={"headerIcon"}  onDataChange={onDataChange}/>
                </div>
            </Row>
        </div>
    );
}
