import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";

const requestInit = async (body = {}) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
  const jwtToken = currentAuthenticatedUser.signInUserSession?.accessToken?.jwtToken

  let headersObj = {}
  if(jwtToken) {
    headersObj['Authorization'] = 'Bearer ' + jwtToken
  }

  return {
    headers: headersObj,
    body: body
  }
}

export const getShopifyProducts = async (customerUuid) => {
    try {
        let apiName = "blustreamREST";
        let path = "/shopify-intergration/shopify/products";
        let body = {
            accountId: customerUuid
        };

        let init = await requestInit(body);

        const response = await API.post(apiName, path, init);
        return response?.products;
    } catch (error) {
      console.log(error);
      return [];
    }
};
