import React from "react";
import { useSelector } from "react-redux";
import { Switch, BrowserRouter, Redirect, Route } from "react-router-dom";
import CreateAccount from "pages/user/CreateAccount";
import ConfirmEmail from "pages/user/CreateAccount/ConfirmEmail";
import ConfirmPassword from "pages/user/ForgetPassword/ConfirmPassword";
import ForgetPassword from "pages/user/ForgetPassword";
import Login from "pages/user/Login";
import PublicRoutes from "./PublicRoutes";
import AccountLayoutRoute from "./AccountRoute";
import MainLayoutRoute from "./MainLayoutRoute";
import { createBrowserHistory } from "history";
import { Dashboard } from "pages/dashboard";
import Admin from "pages/Admin";
import TenantSelection from "pages/user/Accounts/Selection";
import Settings from "pages/account/Settings";
import Product from "pages/manage/Product";
import Contacts from "pages/manage/Contacts";
import ContactDetailPage from "pages/manage/ContactDetailPage";
import ProductDetailPage from "pages/manage/ProductDetailPage";
import ProductMapping from "pages/manage/ProductMapping";
import Touchpoints from "pages/touchPoints";
import Support from "pages/support";
import CreateContactPage from "../pages/manage/CreateContactPage";
import WorkFlow from "pages/touchPoints/WorkFlow";
import TouchpointDetail from "pages/touchPoints/TouchpointDetail";
import ContactsDetailPage from "pages/manage/ContactsDetailPage";
import Microsite from "pages/microsite/Index";
import WebchatEmailTemplatePage from "pages/webchat/WebchatEmailTemplatePage";
import WebchatBrandingPage from "pages/webchat/WebchatBrandingPage";
import WebchatSettingsPage from "pages/webchat/WebchatSettingsPage";
import FormBuilder from "pages/microsite/FormBuilder";

import TermsConditionPDF from "pages/user/Accounts/Terms&ConditionPDF";
import PrivacyPolicy from "pages/user/Accounts/PrivacyPolicy";

import MicrositeUserForm from "pages/microsite/MicrositeUserForm";
import BillingIframe from "pages/account/BillingIframe";
import BillingRoute from "pages/user/Accounts/BillingRoute";
import ResponsiveBilling from "pages/account/ResponsiveBilling";

import Integration from "pages/Integration/integartion";
import ChatBot from "pages/manage/ChatBot";
import ShopifyInstall from "pages/Integration/ShopifyInstall";
import ShopifyRedirect from "pages/Integration/ShopifyRedirect";
import UnboxingTour from "pages/Integration/UnboxingTour";
import SetNewPassword from "pages/user/ForgetPassword/SetNewPassword";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

export const Routes = () => {
  const account = useSelector((state) => state.loginReducer.groups);
  const { groups } = useSelector((state) => state.loginReducer);

  return (
    <BrowserRouter basename="/" history={createBrowserHistory()}>
      <Switch>
        <PublicRoutes
          exact
          path={"/signin"}
          render={(matchprops) => <Login {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/signup"}
          render={(matchprops) => <CreateAccount {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/forgot-password"}
          render={(matchprops) => <ForgetPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/set-new-password"}
          render={(matchprops) => <SetNewPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/billingpage"}
          render={(matchprops) => <BillingRoute {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/reset-password"}
          render={(matchprops) => <ConfirmPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/reset-password?confirmationcode=:code+email=:email"}
          render={(matchprops) => <ConfirmPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/confirm_sign_up"}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/confirm_sign_up?confirmationcode=:code"}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />
        <AccountLayoutRoute
          exact
          path={"/setup?requestid=:id"}
          render={(matchprops) => <TenantSelection {...matchprops} />}
        />
        <AccountLayoutRoute
          exact
          path={"/setup"}
          render={(matchprops) => <TenantSelection {...matchprops} />}
        />
        <AccountLayoutRoute
          exact
          path={"/unboxing-tour"}
          render={(matchprops) => <UnboxingTour {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/"}
          render={(matchprops) => <Dashboard {...matchprops} />}
        />
        {(CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "admin") || CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "roadmin")) &&
          (
            <MainLayoutRoute
              path={"/admin"}
              render={(matchprops) => <Admin {...matchprops} />}
            />
          )
        }
        <MainLayoutRoute
          exact
          path={"/touchpoints"}
          render={(matchprops) => <Touchpoints {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/touchpoints/workflow"}
          render={(matchprops) => <WorkFlow {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/touchpoint-detail"}
          render={(matchprops) => <TouchpointDetail {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/support"}
          render={(matchprops) => <Support {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/product"}
          render={(matchprops) => <Product {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/settings"}
          render={(matchprops) => <Settings {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/product-detail"}
          render={(matchprops) => <ProductDetailPage {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/contacts-detail"}
          render={(matchprops) => <ContactsDetailPage {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/contacts"}
          render={(matchprops) => <Contacts {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/contacts/create-contact"}
          render={(matchprops) => <CreateContactPage {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/contacts/contact-detail"}
          render={(matchprops) => <ContactDetailPage {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/contact-form"}
          render={(matchprops) => <Microsite {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/contact-form/contact-form-builder"}
          render={(matchprops) => <FormBuilder {...matchprops} />}
        />

        {CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "beta") && (
          <MainLayoutRoute
            exact
            path={"/webchat/email-template"}
            render={(matchprops) => <WebchatEmailTemplatePage {...matchprops} />}
          />
        )}

        {CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "beta") && (
          <MainLayoutRoute
            exact
            path={"/webchat/branding"}
            render={(matchprops) => <WebchatBrandingPage {...matchprops} />}
          />
        )}

        {CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "beta") && (
          <MainLayoutRoute
            exact
            path={"/webchat/settings"}
            render={(matchprops) => <WebchatSettingsPage {...matchprops} />}
          />
        )}

        {CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "beta") && (
          <MainLayoutRoute path={"/webchat"} render={(matchprops) => <Redirect {...matchprops} to="/webchat/email-template" />} />
        )}

        {CurrentUserSessionHelper.userCognitoGroupsInclude(groups, "admin") &&
          (
            <MainLayoutRoute
              exact
              path={"/chat-interface"}
              render={(matchprops) => <ChatBot {...matchprops} />}
            />
          )
        }

        <MainLayoutRoute
          exact
          path={"/responsive-billingpage"}
          render={(matchprops) => <ResponsiveBilling {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/integration"}
          render={(matchprops) => <Integration {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/product-mapping"}
          render={(matchprops) => <ProductMapping {...matchprops} />}
        />

        <Route path="/termsandCondition" component={TermsConditionPDF} />

        <Route path="/privacy" component={PrivacyPolicy} />

        <Route
          path="/contact-form/contact-form-builder/m/:id"
          component={MicrositeUserForm}
        />
        <Route
          path="/contact-form-builder/m/:id"
          component={MicrositeUserForm}
        />

        <Route path="/billingiframe" component={BillingIframe} />

        <Route
          exact
          path={"/shopify-install"}
          render={(matchprops) => <ShopifyInstall {...matchprops} />}
        />
        <Route
          exact
          path={"/shopify-redirect"}
          render={(matchprops) => <ShopifyRedirect {...matchprops} />}
        />

        <MainLayoutRoute render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
