import React from "react";
import { useHistory } from "react-router-dom";

export default function WebchatTabs({ activeTab, tabItems }) {
  const history = useHistory();
  
  const renderTab = (tab) => (
    <div
      key={tab.tabKey}
      style={{
        display: "flex",          
        flexDirection: "column",    
        justifyContent: "center",   
        alignItems: "center",     
        textAlign: "center",
        cursor: "pointer",
        borderBottom: activeTab === tab.tabKey ? "3.5px solid #124173" : "none",
        flex: 1,
        padding: "8px 2px",  
      }}
      onClick={() => history.push(tab.route)}
    >
      <img
        className="tab-icon"
        src={tab.icon}
        alt={tab.altText}
        style={{ marginBottom: "4px" }}
      />
      <div style={{ fontSize: "12px", fontWeight: activeTab === tab.tabKey ? "bold" : "normal" }}>
        <p className="tab-label" style={{ margin: 0 }}>{tab.label}</p>
      </div>
    </div>
  );
  
  return <div className="tabs-header" style={{ display: "flex" }}>{tabItems.map(renderTab)}</div>;
}
