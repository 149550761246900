import React, { useEffect, useState } from "react";
import "../../css/emailTemplate.css";
import { useHistory, useLocation } from "react-router-dom";

import { useSpecialOnlyUserCognitoGroups } from "hooks/CognitoGroupHooks";
import templateIcon from "assets/Images/templace-icon.svg";
import chatStreamIcon from "assets/Images/chatStream-icon.svg";
import settingIcon from "assets/Images/setting-icon.svg";
import WebchatSettingsTabMenu from "../../Components/Webchat/WebchatSettingsTabMenu";
import WebchatBrandingSaveButton from "../../Components/Webchat/WebchatBrandingSaveButton";
import WebchatTabs from "../../Components/Webchat/WebchatTabs";
import { Divider } from "antd";

const DEFAULT_PATH = '/webchat/email-template';

export default function WebchatSettingsPage() {

  const [activeTab, setActiveTab] = useState('');

  const history = useHistory();
  const location = useLocation();
  const specialOnlyUserCognitoGroups = useSpecialOnlyUserCognitoGroups();
  const [unsavedChanges, setUnsavedChanges] = useState(false);


  useEffect(() => {
    if (!specialOnlyUserCognitoGroups.includes("beta")) {
      history.push("/");
    }

    let currentPath = location.pathname
    if (currentPath === "/webchat") {
        history.push(DEFAULT_PATH);
    } else if (currentPath === "/webchat/email-template") {
        setActiveTab('emailTemplates');
    } else if (currentPath === "/webchat/branding") {
        setActiveTab('branding');
    } else if (currentPath === "/webchat/settings") {
        setActiveTab('settings');
    }

  } , [specialOnlyUserCognitoGroups, history, location.pathname]);

  const tabItems = [
    { tabKey: "emailTemplates", icon: templateIcon, altText: "Email Templates", label: "Email Templates", route: "/webchat/email-templates" },
    { tabKey: "branding", icon: chatStreamIcon, altText: "Branding", label: "Branding", route: "/webchat/branding" },
    { tabKey: "settings", icon: settingIcon, altText: "Settings", label: "Settings", route: "/webchat/settings" },
  ];

  return (
    <div className="email-template-container">
      {/* Header Section */}
      <div className="editor-header">
        <div className="editor-header-left">
          <h2><strong>Web Chat</strong></h2>
          <p className="subheading">Easily connect with your contacts in a web-based, mobile-enabled channel.</p>
        </div>
        <div className="editor-header-right">
            <WebchatBrandingSaveButton unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
          <button className="preview-button" hidden={true}>Preview</button>
        </div>
      </div>

      <Divider style={{ marginTop: '20px' }} />

      <div className="main-container">
        {/* Editor Section */}
        <div className="editor-main-container">
          <div className="editor-container">
            
                {/* PUT CONTENT HERE */}

          </div>
        </div>

        {/* Templates Section */}
        <div className="templates-list-header-container">
          {/* Tabs Header */}
          <WebchatTabs activeTab={activeTab} tabItems={tabItems} />
          {/* Tab Content */}
          <div className="template-list-container">

            {activeTab === 'settings' && <WebchatSettingsTabMenu />}
            
          </div>
        </div>
      </div>
    </div>
  );
}