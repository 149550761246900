//LoginActions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const UPDATE_COMPANY_ACCOUNT = "UPDATE_COMPANY_ACCOUNT";
export const UPDATE_USER_GROUPS = "UPDATE_USER_GROUPS";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const SET_ACCOUNT_REQUESTS = "SET_ACCOUNT_REQUESTS";
export const SET_ACCOUNT_STATUS = "SET_ACCOUNT_STATUS";
export const SET_RO_ADMIN = "SET_RO_ADMIN";

//RegisterActions

export const SIGNUP = "SIGNUP";
export const ROLES = "ROLES";

//Setting Actions

export const SET_CONTACTS = "SET_CONTACTS";
export const SHOW_LODER = "SHOW_LODER";
export const SET_TNC = "SET_TNC";
export const SET_TAGS = "SET_TAGS";
export const SET_PRIVACYPOLICY = "SET_PRIVACYPOLICY";
export const SET_TNCSHOW = "SET_TNCSHOW";
export const SET_PRSHOW = "SET_PRSHOW";
export const SET_DATA = "SET_DATA";

//Touchpoint Action
export const TOUCHPOINT_DATA = "TOUCHPOINT_DATA";

//microsite default style
export const SET_MICROSITE_DEFAULT_STYLE = "SET_MICROSITE_DEFAULT_STYLE";

// microsite form elements
export const SET_MICROSITE_FORM_ELEMENTS = "SET_MICROSITE_FORM_ELEMENTS";

//microsite publish functionality
export const SET_TEMPLATE_NAME = "SET_TEMPLATE_NAME";
export const SET_MICROSITE_ID = "SET_MICROSITE_ID";

//microsite unpublish functionality
export const SET_DISABLED = "SET_DISABLED";

// microsite currentFormElement ID
export const SET_MICROSITE_FORM_ELEMENT_ID = "SET_MICROSITE_FORM_ELEMENT_ID";

//microsite activeKey
export const SET_MICROSITE_ACTIVE_KEY = "SET_MICROSITE_ACTIVE_KEY";
export const SET_LOGO_KEY = "SET_LOGO_KEY";

//shopify profile id
export const SET_SHOPIFY_PROFILE_ID = "SET_SHOPIFY_PROFILE_ID";

export const SET_VERIFICATION_DETAILS = "SET_VERIFICATION_DETAILS";

// Webchat Brand Settings Actions
export const SET_PAGE = "SET_PAGE";
export const SET_THEME = "SET_THEME";
export const SET_CHAT = "SET_CHAT";
export const SET_PWA = "SET_PWA";
export const SET_LINKS = "SET_LINKS";
export const SET_BRANDING_ELEMENTS = "SET_BRANDING_ELEMENTS";
export const UPDATE_BRANDING_ELEMENTS = "UPDATE_BRANDING_ELEMENTS";



// Webchat Email Template Actions
export const SET_EMAIL_TEMPLATE = "SET_EMAIL_TEMPLATE";
export const FETCH_EMAIL_TEMPLATES_SUCCESS = "FETCH_EMAIL_TEMPLATES_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const SAVING_EMAIL_TEMPLATE = "SAVING_EMAIL_TEMPLATE";
export const SAVED_EMAIL_TEMPLATE = "SAVED_EMAIL_TEMPLATE";
export const ERROR_SAVING_EMAIL_TEMPLATE = "ERROR_SAVING_EMAIL_TEMPLATE";
