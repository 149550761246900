import * as actions from "store/actions";
import {
  getCustomerEmailTemplates,
  addCustomerEmailTemplate,
} from "apiService/RESTApi";

// Action Creators
export const templateSaving = () => ({
  type: actions.SAVING_EMAIL_TEMPLATE,
});
export const templateSaved = (template) => ({
  type: actions.SAVED_EMAIL_TEMPLATE,
  payload: template,
});
export const templateSaveError = (error) => ({
  type: actions.ERROR_SAVING_EMAIL_TEMPLATE,
  payload: error,
});

export const setEmailTemplate = (template) => ({
  type: actions.SET_EMAIL_TEMPLATE,
  payload: template,
});

// Get data from server
export const fetchEmailTemplates = (customerUuid) => async (dispatch, ) => {
  try {
    const fetchedTemplates = await getCustomerEmailTemplates(customerUuid);
    dispatch({
      type: actions.FETCH_EMAIL_TEMPLATES_SUCCESS,
      payload: fetchedTemplates,
    });
    return fetchedTemplates;
  } catch (error) {
    console.error("Error fetching email template:", error);
    return null;
  }
};

// Update data on server with user feedback state
export const saveTemplate = (payload) => async (dispatch) => {
  if (!payload) {
    console.log("blank payload");
    return;
  }

  dispatch(templateSaving());
  try {
    const response = await addCustomerEmailTemplate(payload);
    dispatch({
      type: actions.UPDATE_EMAIL_TEMPLATE,
      payload: response,
    });

    dispatch(templateSaved(response));
    await dispatch(fetchEmailTemplates(payload.customerUuid));
    return response;
  } catch (error) {
    console.error("Error saving template:", error);
    dispatch(templateSaveError(error));
    return;
  }
};
