import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// order matters (ie. beta should be after customer uuids, internal should be last)
const SPECIAL_GROUPS_NAMES = ["beta", "admin", "internal"];

export const useAllUserCognitoGroups = () => {
    const groups = useSelector((state) => state.loginReducer.groups);
    if (!groups) {
        return [];
    }

    // If special group exists, remove and add to end
    SPECIAL_GROUPS_NAMES.forEach(group => {
        if (groups.includes(group)) {
            groups.push(groups.splice(groups.indexOf(group), 1)[0]);
        }
    });

    // group[0] will now be a customers cognito group UUID
    // Groups before special groups will be the customer groups
    // Last groups will be the special groups
    return groups;
};

export const useCompanyOnlyUserCognitoGroups = () => {
    const groups = useSelector((state) => state.loginReducer.groups);

    let companyOnlyGroups = [];
    if(!groups) {
        companyOnlyGroups = groups.filter(group => group.length === 36);
    }

    return companyOnlyGroups;
};

export const useSpecialOnlyUserCognitoGroups = () => {
    const groups = useSelector((state) => state.loginReducer.groups);
    const specialOnlyUserCognitoGroups = groups.filter(group => SPECIAL_GROUPS_NAMES.includes(group));
    return specialOnlyUserCognitoGroups ;
}
