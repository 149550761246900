import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../../css/WebchatBranding.css';
import { Button } from 'antd';

import Icon, {
  
} from "@ant-design/icons";

const WebchatBranding = () => {
  
  const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);

  const [showSettings, setShowSettings] = useState(false);
  const [isEmailNotificationChecked, setIsEmailNotificationChecked] = useState(false);
  const [isPushNotificationChecked, setPushEmailNotificationChecked] = useState(false);

  const openSettings = () => setShowSettings(true);
  const closeSettings = () => setShowSettings(false);

  const defaultmainElementColor = '#e2ebf3';
  const darkBlueBlustreamColor = '#124173';
  const defaultTextWhiteColor = '#fff';
  const defaultbuttonBackgroundColor = '#fff';
  const defaultbuttonTextColor = "#000";
  const defaultheaderImage = 'https://app.blustream.io/favicon.png';

  const BackArrowIconSvg = () => (
    <svg
      width="35"
      height="35"
       viewBox="0 0 512 512"
       fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
       d="m339.96 378.88-124.4-121.86 121.34-124.42-29.18-28.66-150.02 154.1 153.6 150.02z"
         fill="currentColor"
      />
    </svg>
  );

  const BackArrowIcon = (props) => <Icon component={BackArrowIconSvg} {...props} />;
  return (
      <div className="app-container">
        {!showSettings ? (
          <div className="screen-container">
              <div className="header">
                <div className="header-icon">
                  <img
                      src={page?.headerIcon || defaultheaderImage}
                      alt="Chat Icon"
                      className="chat-icon"
                  />
                </div>
                <button className="hamburger-btn" onClick={openSettings} style={{ color: page?.mainElementColor || defaultmainElementColor}}>
                  ☰
                </button>
              </div>

              <div className="chat-container">
                <div className="incoming-message">
                  <img
                      src={page.brandMessageIcon}
                      alt="Incoming Icon"
                      className="incoming-icon"
                  />
                  <div className="bubble-left" style={{ backgroundColor: page?.brandMessageBackgroundColor || defaultmainElementColor }}>
                    <p style={{ color: page?.brandMessageTextColor || defaultbuttonTextColor }}>
                    Hi from our company! This is an example welcome message. Do you want to learn more about your product?
                    </p>
                  </div>
                </div>

                <div className="bubble-right" style={{ backgroundColor: page?.contactMessageBackgroundColor || darkBlueBlustreamColor }}>
                  <p style={{ color: page?.contactMessageTextColor || defaultTextWhiteColor }}>Yes</p>
                </div>

                <div className="incoming-message">
                <img
                  src={page.brandMessageIcon}
                  alt="Incoming Icon"
                  className="incoming-icon"
                />
                  <div className="bubble-left" style={{ backgroundColor: page?.brandMessageBackgroundColor || defaultmainElementColor}}>
                    <p style={{ color: page?.brandMessageTextColor || defaultbuttonTextColor }}>This is an example response.</p>
                  </div>
                </div>
              </div>

              <div className="chat-input-wrapper">
                <div className="input-bubble">
                  <input
                      className="chat-input"
                      type="text"
                      placeholder="Type a message..."
                  />
                </div>
                <Button className="circle-btn send-btn" style={{ backgroundColor: page?.mainElementColor || defaultbuttonBackgroundColor,
                 color: page?.buttonTextColor || defaultbuttonTextColor }}>↑</Button>
              </div>
            </div>
        ) : (
            // --- Settings Screen ---
            <div className="screen-container">
              <div className="header">
              <Button 
                className='back-btn'
                style={{ color: page?.mainElementColor || darkBlueBlustreamColor, border: 'none' }}
                onClick={closeSettings}
                icon={<BackArrowIcon/>}
              ></Button>
                <div className="header-icon">
                  <img
                      src={page?.headerIcon || defaultheaderImage}
                      alt="Chat Icon"
                      className="chat-icon"
                  />
                </div>
              </div>
              <div className="settings-container">
                <a href={page?.FAQLink} target="_blank" rel="noopener noreferrer">
                  <span className="settings-item settings-item-bold">Frequently Asked Questions</span>
                </a>
                <a href={page?.SupportLink} target="_blank" rel="noopener noreferrer"> 
                  <span className="settings-item settings-item-bold">Customer Support</span>
                </a>
                <div className="settings-item settings-item-toggle">
                  <span>Email Notifications</span>
                  <label className="switch">
                    <input type="checkbox" checked={isEmailNotificationChecked} onChange={() => setIsEmailNotificationChecked(!isEmailNotificationChecked)} />
                    <span className="slider" style={{ backgroundColor: isEmailNotificationChecked ? page?.mainElementColor || darkBlueBlustreamColor : "#ccc" }}></span>
                  </label>
                </div>
                <div className="settings-item settings-item-toggle">
                  <span>Push Notifications</span>
                  <label className="switch">
                    <input type="checkbox" checked={isPushNotificationChecked} onChange={() => setPushEmailNotificationChecked(!isPushNotificationChecked)} />
                    <span className="slider" style={{ backgroundColor: isPushNotificationChecked ? page?.mainElementColor || darkBlueBlustreamColor : '#ccc' }}></span>
                  </label>
                </div>
                <a href={page?.ShopLink} target="_blank" rel="noopener noreferrer">
                  <span className="settings-item settings-item-bold">Shop</span>
                </a>
                <div className="button-container">
                  <Button className="logout-btn">Log out</Button>
                  <Button className="add-home-btn" style={{ backgroundColor: page?.mainElementColor || darkBlueBlustreamColor,
                   color: page?.buttonTextColor || defaultTextWhiteColor }}>Add to home screen</Button>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

export default WebchatBranding;