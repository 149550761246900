/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Spin, Modal, Tooltip, Typography, Skeleton } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

import {
  logout,
  setAccountDetails,
  setLoginData,
  updateCompanyAccount,
  updateUserGroups,
} from "store/actions/loginAction";
import LinkStore from "Components/LinkStore/LinkStore";
import ShopifySteppers from "Components/ShopifySteppers/ShopifySteppers";
import OrgRequestAccess from "Components/OrgRequestAccess/OrgRequestAccess";
import constants from "utility/Constants";
import colorfulBlustreamLogo from "./../../assets/Images/colorfulBlustreamLogo.svg";
import thankYou from "./../../assets/Images/thankYou.svg";
import sdk from "sdk/Accounts";
import { ShopifyProfileByAccountId } from "graphql/queries";
import { setVerificationDetails } from "store/actions/loginAction";

const UnboxingTour = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { admin_groups_self, verificationDetails, userData } = useSelector(
    (state) => state.loginReducer
  );

  const [loader, setloader] = useState(true);
  const [currentState, setcurrentState] = useState(null);
  const [organizationList, setOrganizationList] = useState(null);
  const [connectedOrganization, setConnectedOrganization] = useState(null);
  const [hasRequestSent, setHasRequestSent] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  useEffect(() => {
    if (Object?.keys(verificationDetails)?.length > 0) {
      getInitialValues(verificationDetails);
    }
  }, [verificationDetails]);

  useEffect(() => {
    if (
      verificationDetails?.is_shopify_store_linked &&
      verificationDetails?.is_user_authorized &&
      !verificationDetails?.shopify_profile?.showSteper &&
      verificationDetails?.shopify_profile?.isProfileLock
    ) {
      getShopifyProfileDetails();
    }
  }, []);

  const getInitialValues = async (response) => {
    if (response?.is_shopify_store_linked && response?.is_user_authorized) {
      if (!response?.shopify_profile?.showSteper) {
        if (!response?.shopify_profile?.isProfileLock) {
          setProfileLoading(true);
          const selectedCompany = await sdk.fecthCompanies(
            response?.shopify_profile?.account
          );
          const accountDetails = selectedCompany?.data?.getCustomerAccount;
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(
            currentSession.refreshToken,
            (err, session) => {
              const { accessToken } = session;
              if (CurrentUserSessionHelper.accessTokenHasCognitoGroups(accessToken)) {
                const response = Auth.currentUserInfo();
                response.then(async (data) => {
                  dispatch(setLoginData(session, data?.attributes));
                  dispatch(updateUserGroups(session, data?.attributes));
                  dispatch(updateCompanyAccount(accountDetails));
                  if (accountDetails?.id) {
                    let account = await sdk.fetchAccount(accountDetails?.id);
                    dispatch(setAccountDetails(account));
                  }
                  localStorage.removeItem("storeDetails");
                  history.push("/");
                  setProfileLoading(false);
                  setloader(false);
                });
              }
            }
          );
        } else {
          setShowThankYouModal(true);
          setloader(false);
        }
      }
      if (response?.shopify_profile?.showSteper) {
        setcurrentState(
          constants?.SHOPIFY_ONBOARDING_FLOW?.CURRENT_STATE?.SHOW_STEPPERS
        );
        setloader(false);
      }
    } else if (response?.is_shopify_store_linked) {
      setcurrentState(
        constants?.SHOPIFY_ONBOARDING_FLOW?.CURRENT_STATE?.REQUEST_ACCESS
      );
      const organization = await sdk.fecthCompanies(
        response?.shopify_profile?.account
      );
      await getRequest(response?.shopify_profile?.account);
      setConnectedOrganization(organization?.data?.getCustomerAccount);
      setloader(false);
    } else {
      setcurrentState(
        constants?.SHOPIFY_ONBOARDING_FLOW?.CURRENT_STATE?.LINK_STORE
      );
      const onlyCompanyGroups = admin_groups_self?.filter(
        (item) => item?.length === 36
      );
      await Promise.all(
        onlyCompanyGroups.map((item) => sdk.fecthCompanies(item))
      ).then(async (res) => {
        await Promise.all(
          res?.map(async (organization) => {
            const attachedShopifyProfile = await API.graphql(
              graphqlOperation(ShopifyProfileByAccountId, {
                account: organization?.data?.getCustomerAccount?.id,
              })
            );
            const bool =
              attachedShopifyProfile?.data?.ShopifyProfileByAccountId?.items
                ?.length > 0
                ? true
                : false;
            return {
              ...organization?.data?.getCustomerAccount,
              isshopifyProfileAttached: bool,
            };
          })
        ).then((updatedResponse) => {
          //sorted array from not connected to connected.
          updatedResponse
            ?.sort(
              (a, b) => a.isshopifyProfileAttached - b.isshopifyProfileAttached
            )
            ?.reverse();
          setOrganizationList(updatedResponse);
        });
      });
      setloader(false);
    }
  };

  const getShopifyProfileDetails = async () => {
    setProfileLoading(true);
    let apiName = "blustreamREST";
    let path = "/shopify-intergration/shopify-profile";
    let body = {
      shopifyProfileId: verificationDetails?.shopify_profile?.id,
    };

    var response = await API.post(apiName, path, { body: body });

    dispatch(
      setVerificationDetails({
        ...verificationDetails,
        shopify_profile: {
          ...verificationDetails?.shopify_profile,
          isProfileLock: response?.getShopifyProfile?.isProfileLock,
        },
      })
    );
    setProfileLoading(false);
  };

  const handleSignOut = async () => {
    Auth.signOut()
      .then((data) => {
        localStorage.removeItem("storeDetails");
        dispatch(logout());
      })
      .catch((err) => console.log(err));
  };

  const getRequest = async (account) => {
    const request = await sdk?.getCustomerAccountRequest(
      account,
      userData?.email
    );
    setHasRequestSent(request?.length > 0);
    return request;
  };

  return loader ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" className="shopify-loader" />
      <h1>Please wait. We’re checking!</h1>
    </div>
  ) : (
    <>
      <div className="shopify-steper-main-container">
        <Row justify="center">
          <Col span={20}>
            <div className="colorful-blustream-logo-container">
              <img
                className="colorful-blustream-logo"
                src={colorfulBlustreamLogo}
                alt="colorfulBlustreamLogo"
              />
            </div>
          </Col>
        </Row>
        <>
          {currentState ===
            constants?.SHOPIFY_ONBOARDING_FLOW?.CURRENT_STATE?.LINK_STORE && (
            <LinkStore organizationList={organizationList} />
          )}
          {currentState ===
            constants?.SHOPIFY_ONBOARDING_FLOW?.CURRENT_STATE
              ?.REQUEST_ACCESS && (
            <OrgRequestAccess
              connectedOrganization={connectedOrganization}
              hasRequestSent={hasRequestSent}
              getRequest={getRequest}
            />
          )}
          {currentState ===
            constants?.SHOPIFY_ONBOARDING_FLOW?.CURRENT_STATE
              ?.SHOW_STEPPERS && <ShopifySteppers />}
        </>
        <p className="shopify-integration-copyright-text">
          © 2023 Blustream copyright all rights reserved.
        </p>
      </div>
      <Modal
        title={null}
        visible={showThankYouModal}
        closeIcon={
          <Tooltip title="Log out">
            <LogoutOutlined
              style={{ fontSize: "20px" }}
              onClick={() => {
                handleSignOut();
              }}
            />
          </Tooltip>
        }
        footer={null}
        className="thank-you-modal"
        centered
      >
        {profileLoading ? (
          <div className="thank-you-modal-content">
            <Skeleton.Image
              active
              style={{ width: "183px", height: "179px" }}
            />
            <Skeleton.Input active style={{ width: "400px", height: "32px" }} />
            <Skeleton active rows={4} />
          </div>
        ) : (
          <div className="thank-you-modal-content">
            <img className="thank-you-image" src={thankYou} alt="giftBox" />
            <Typography.Text className="thank-you-text">
              Got it! Our team is building your product journeys for your
              approval.
            </Typography.Text>
            <Typography.Text className="thank-you-text-2">
              We’ve sent an email to{" "}
              <span className="highlighted-text">{userData?.email}</span> to
              schedule your review and approval with Blustream’s delivery team.
              If you have any questions, reach out to{" "}
              <span className="highlighted-text">help@blustream.io.</span> We
              can’t wait to start sending your customers messages!
            </Typography.Text>
          </div>
        )}
      </Modal>
    </>
  );
};

export default UnboxingTour;
