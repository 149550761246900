import * as actions from "store/actions";

const initialState = {
    emailTemplate: {
        customerUuid: "customer-uuid",
        templateId: "selected-template-id",
        name: "selected-template-name",
        subject: "selected-template-subject",
        body: "body-content",
    },
    emailTemplates: [],
};

const WebchatEmailTemplatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_EMAIL_TEMPLATE:
            return {
                ...state,
                emailTemplate: action.payload
            };
        case actions.FETCH_EMAIL_TEMPLATES_SUCCESS:
            const sortedTemplates = action.payload.response.data[0].slice().sort((a, b) => a.id - b.id);
            return {
                ...state,
                emailTemplates: sortedTemplates,
            };
        default:
            return state;
    }
};

export default WebchatEmailTemplatesReducer;