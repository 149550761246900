import React, { useState } from 'react';
import WebchatBrandingTabMenuTheme from './WebchatBrandingTabMenuTheme';
import WebchatBrandingTabMenuChat from './WebchatBrandingTabMenuChat';
import WebchatBrandingTabMenuPWA from './WebchatBrandingTabMenuPWA';
import WebchatBrandingTabMenuLinks from './WebchatBrandingTabMenuLinks';
import { Collapse } from 'antd';
import '../../css/WebchatBranding.css';

const { Panel } = Collapse;

const WebchatBrandingTabMenu = ({ onDataChange }) => {
  const [key, setKey] = useState([]);

  return (
    <div style={styles.webchatBrandingTabMenu}>
      <div>
          <Collapse
            activeKey={key}
            expandIconPosition="right"
            onChange={(keys) => setKey(keys)}
            className='ant-collapse-header'
          >
            <Panel className='ant-collapse-item' style={styles.panelHeader} header={<span style={styles.panelHeader}>Theme</span>} key="1">
              <WebchatBrandingTabMenuTheme onDataChange={onDataChange} />
            </Panel>
             <Panel className='ant-collapse-item' header={<span style={styles.panelHeader}>Chat</span>} key="2">
              <WebchatBrandingTabMenuChat onDataChange={onDataChange}/>
            </Panel>
             <Panel className='ant-collapse-item' header={<span style={styles.panelHeader}>PWA</span>} key="3">
              <WebchatBrandingTabMenuPWA onDataChange={onDataChange}/>
            </Panel>
             <Panel className='ant-collapse-item' header={<span style={styles.panelHeader}>Links</span>} key="4">
              <WebchatBrandingTabMenuLinks onDataChange={onDataChange} />
            </Panel>

          </Collapse>
      </div>
    </div>
  );

};


const styles = {
  webchatBrandingTabMenu: {
    background: '#e2ebf3 !important',
    height: '80%',
    width: '100%',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    position: 'relative',
    left: '10px'
    
  },
  panelHeader: {
    fontWeight: 'bold',
  },
}

export default WebchatBrandingTabMenu;