import React, { useEffect, useState } from "react";
import "../../css/emailTemplate.css";
import { useHistory, useLocation } from "react-router-dom";


import { useSpecialOnlyUserCognitoGroups } from "hooks/CognitoGroupHooks";
import templateIcon from "assets/Images/templace-icon.svg";
import chatStreamIcon from "assets/Images/chatStream-icon.svg";
import settingIcon from "assets/Images/setting-icon.svg";
import WebchatBrandingTabMenu from "../../Components/Webchat/WebchatBrandingTabMenu";
import WebchatBranding from "../../Components/Webchat/WebchatBranding";

import { setPageSettings } from "store/actions/WebchatBrandSettingsAction";
import WebchatBrandingSaveButton from "../../Components/Webchat/WebchatBrandingSaveButton";
import { fetchBrandingElements } from 'store/actions/WebchatBrandSettingsAction';
import { useDispatch, useSelector } from "react-redux";
import { useActiveCompanyAlias } from "hooks/CompanyHooks";  
import WebchatTabs from "../../Components/Webchat/WebchatTabs";
import { Divider } from "antd";

const DEFAULT_PATH = '/webchat/email-template';


export default function WebchatSettingsPage() {

  const [activeTab, setActiveTab] = useState('');

  const { page } = useSelector((state) => state.WebchatBrandSettingsReducer);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const specialOnlyUserCognitoGroups = useSpecialOnlyUserCognitoGroups();
  const dispatch = useDispatch();
  const activeCompanyAlias = useActiveCompanyAlias();


  useEffect(() => {
    if (!specialOnlyUserCognitoGroups.includes("beta")) {
      history.push("/");
    }

    let currentPath = location.pathname
    if (currentPath === "/webchat") {
        history.push(DEFAULT_PATH);
    } else if (currentPath === "/webchat/email-template") {
        setActiveTab('emailTemplates');
    } else if (currentPath === "/webchat/branding") {
        setActiveTab('branding');
    } else if (currentPath === "/webchat/settings") {
        setActiveTab('settings');
    }

    getBrandingElementData();

  } , [specialOnlyUserCognitoGroups, history, location.pathname]);

  const tabItems = [
    { tabKey: "emailTemplates", icon: templateIcon, altText: "Email Templates", label: "Email Templates", route: "/webchat/email-templates" },
    { tabKey: "branding", icon: chatStreamIcon, altText: "Branding", label: "Branding", route: "/webchat/branding" },
    { tabKey: "settings", icon: settingIcon, altText: "Settings", label: "Settings", route: "/webchat/settings" },
  ];


  useEffect(async () => {
    const subDomain = await activeCompanyAlias;
    dispatch(fetchBrandingElements(subDomain)).then((data) => {
      if (data) {
        dispatch(setPageSettings(data));
      } else {
        dispatch(setPageSettings({}));
      }
    });
  }, [dispatch]);

  const getBrandingElementData = async () => {
    try {
      // get data from server
      const brandingElementValues = {
        pageTitle: page.title,
        favicon: page.favicon,
        mainElementColor: page.mainElementColor,
        buttonBackgroundColor: page.buttonBackgroundColor,
        buttonTextColor: page.buttonTextColor,
        headerIcon: page.headerIcon,
        brandMessageBackgroundColor: page.brandMessageBackgroundColor,
        brandMessageTextColor: page.brandMessageTextColor,
        brandMessageIcon: page.brandMessageIcon,
        contactMessageBackgroundColor: page.contactMessageBackgroundColor,
        contactMessageTextColor: page.contactMessageTextColor,
        appName: page.brandLongName,
        shortName: page.brandShortName,
        homeScreenIcon: "",
        notificationIcon: "",
        FAQLink: page.FAQLink,
        SupportLink: page.SupportLink,
        ShopLink: page.ShopLink,
      }
      dispatchEvent(setPageSettings(brandingElementValues));

    } catch (error) {

    }
  };

    const handleDataChange = () => {
    setUnsavedChanges(true);
  };

  return (
    <div className="email-template-container">
      {/* Header Section */}
      <div className="editor-header">
        <div className="editor-header-left">
          <h2><strong>Web Chat</strong></h2>
          <p className="subheading">Easily connect with your contacts in a web-based, mobile-enabled channel.</p>
        </div>
        <div className="editor-header-right">
          <WebchatBrandingSaveButton unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
          <button className="preview-button" hidden={true}>Preview</button>
        </div>
      </div>
      <Divider style={{ marginTop: '20px' }} />

      <div className="main-container" >
        {/* Editor Section */}
        <div className="branding-editor-main-container" style={{backgroundColor: '#fff', width: '100%' }}>
          <div className="editor-container" style={{backgroundColor: '#fff', width: '100%' }}>
            <WebchatBranding/>
          </div>
        </div>

        {/* Templates Section */}
        <div className="templates-list-header-container">
          {/* Tabs Header */}
            <WebchatTabs activeTab={activeTab} tabItems={tabItems} />
          {activeTab === 'branding' && <WebchatBrandingTabMenu onDataChange={handleDataChange} />}
        </div>
      </div>
    </div>
  );
}