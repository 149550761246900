import * as actions from "../actions/index";
import { CurrentUserSessionHelper } from "utility/CurrentUserSessionHelper";

const initialState = {
  token: null,
  roles: [],
  userData: {},
  admin_groups_self: [],
  selectedCompanyName: "",
  accountDetails: {},
  accountRequests: [],
  accountStatus: "",
  roAdmin: false,
  shopifyProfileId: "",
  verificationDetails: {},
};
const loginReducer = (state = initialState, action) => {
  let cognitoGroups = [];
  
  switch (action.type) {
    case actions.UPDATE_LOGIN:
      let userData = { ...action.data };
      cognitoGroups = CurrentUserSessionHelper.getCognitoGroupsFromAccessToken(action.payload.accessToken);

      return {
        ...state,
        token: action.payload.accessToken.jwtToken,
        groups: cognitoGroups,
        admin_groups_self: cognitoGroups,
        userData: { ...userData },
        roAdmin: CurrentUserSessionHelper.userCognitoGroupsInclude(cognitoGroups, "roadmin"),
      };

    case actions.UPDATE_USER_GROUPS:
      cognitoGroups = CurrentUserSessionHelper.getCognitoGroupsFromAccessToken(action.payload.accessToken);
      
      return {
        ...state,
        groups: cognitoGroups?.length > 0 ? state?.groups : cognitoGroups,
        admin_groups_self: cognitoGroups,
      };

    case actions.UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    case actions.UPDATE_COMPANY_ACCOUNT:
      const index = 0;
      const replacementItem = action.payload.id;
      let companyName = action.payload.company;
      let updatedAccount = Object.assign([], state.groups, {
        [index]: replacementItem,
      });
      if (state.selectedCompanyName?.[0] === state.groups?.[0])
        companyName = "";
      return {
        ...state,
        token: state.token,
        groups: updatedAccount,
        selectedCompanyName: companyName,
        userData: { ...state.userData },
      };

    case actions.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload,
        accountStatus: action.payload.accountStatus,
      };

    case actions.SET_ACCOUNT_REQUESTS:
      return {
        ...state,
        accountRequests: action.payload,
      };

    case actions.SET_RO_ADMIN:
      return {
        ...state,
        roAdmin: action.payload,
      };

    case actions.SET_SHOPIFY_PROFILE_ID:
      return {
        ...state,
        shopifyProfileId: action.payload,
      };
    case actions.SET_VERIFICATION_DETAILS:
      return {
        ...state,
        verificationDetails: action.payload,
      };

    case actions.SET_ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: action.payload,
      };

    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
